import { useEffect, useRef, useState } from "react";
import CalendarBox from "./components/CalendarBox";
import { useTheme } from "../../../context/ThemeContext";
import Menu from "./components/Menu";
import CalendarWithNotes from "./components/CalendarWithNotes";
import { TradesService } from "../../../../generated/services/TradesService";
import { generateApiPayloadFilters } from "../../../utils/utils";
import {
  FilterOptions,
  SelectedFilterOption,
  TradeType,
} from "../trades/tradeslog/components/TradesLogTable/TradesLogTable";
import toast from "react-hot-toast";
import { JournalService } from "../../../../generated/services/JournalService";
import JournalModal from "./components/JournalModal";
import { useInView } from "react-intersection-observer";
import { TailSpin } from "react-loader-spinner";

export interface JournalType {
  tradeCount: number;
  totalReturn: number;
  totalCommission: number;
  totalNetReturn: number;
  maxMFE: number;
  maxMAE: number;
  trades: Partial<TradeType>;
  winPercentage: number;
  profitFactor: number;
  date: string; // ISO date format
  journal: ViewJournal;
}

export interface ViewJournal {
  _id: string;
  userId: string;
  date: string;
  notes: string;
  images: string[];
  preTradingQuestions: string[];
  postTradingQuestions: string[];
  createdAt: string;
  updatedAt: string;
}

const Journal = () => {
  const [openSidePannel, setOpenSidePannel] = useState<boolean>(false);
  const [toggleCalendar, setToggleCalendar] = useState<boolean>(false);
  const { theme } = useTheme();
  // Trades states for fetching loading and saving in state
  const [journal, setJournal] = useState<JournalType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [viewJournal, setViewJournal] = useState<JournalType>(
    {} as JournalType
  );
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [allSelectedFilters, setAllSelectedFilters] = useState<
    SelectedFilterOption[]
  >([]);
  const [filtersOptions, setFiltersOptions] = useState<FilterOptions[]>([]);

  useEffect(() => {
    getTradesFilter();
  }, []);

  const handleTagRemove = (itemValue: string) => {
    const updatedSelectedItems = allSelectedFilters.filter(
      (selectedItem) => selectedItem.value !== itemValue
    );
    setAllSelectedFilters(updatedSelectedItems);
  };

  useEffect(() => {
    // if (allSelectedFilters.length) {
    fetchTradesData(generateApiPayloadFilters(allSelectedFilters), {
      page: currentPage,
      limit: 25,
    });
    // }
  }, [allSelectedFilters, currentPage]);

  async function getTradesFilter() {
    try {
      const response = await TradesService.tradesControllerGetTradeFilters(
        "all"
      );
      if (response.success) {
        const mapApiResponseToOptions = (data: any): FilterOptions[] => {
          return Object.entries(data).map(([key, values]) => ({
            name: key.charAt(0).toUpperCase() + key.slice(1),
            //@ts-ignore
            options: values.map((value: string | number) => ({
              value,
              label: value,
            })),
          }));
        };

        setFiltersOptions(mapApiResponseToOptions(response.data));

        // Overwriting the coming filters from backend
        setFiltersOptions((prev) =>
          prev.map((filterOption) => {
            if (filterOption.name === "Side") {
              return {
                name: "Side",
                options: [
                  { value: "buy", label: "Buy" },
                  { value: "sell", label: "Sell" },
                ],
              };
            } else if (filterOption.name === "RiskPercentage") {
              return {
                name: "Risk",
                options: [
                  { value: "0 - 10", label: "0 - 10" },
                  { value: "10 - 20", label: "10 - 20" },
                  { value: "20 - 30", label: "20 - 30" },
                  { value: "30 - 40", label: "30 - 40" },
                  { value: "40 - 50", label: "40 - 50" },
                  { value: "50 - 60", label: "50 - 60" },
                  { value: "60 - 70", label: "60 - 70" },
                  { value: "70 - 80", label: "70 - 80" },
                  { value: "80 - 90", label: "80 - 90" },
                  { value: "90 - 100", label: "90 - 100" },
                ],
              };
            } else if (filterOption.name === "PriceTarget") {
              return {
                name: "PriceTarget",
                options: [
                  { value: "0 - 10", label: "0 - 10" },
                  { value: "10 - 20", label: "10 - 20" },
                  { value: "20 - 30", label: "20 - 30" },
                  { value: "30 - 40", label: "30 - 40" },
                  { value: "40 - 50", label: "40 - 50" },
                  { value: "50 - 60", label: "50 - 60" },
                  { value: "60 - 70", label: "60 - 70" },
                  { value: "70 - 80", label: "70 - 80" },
                  { value: "80 - 90", label: "80 - 90" },
                  { value: "90 - 99999999999", label: "90 - 1000+" },
                ],
              };
            } else if (filterOption.name === "Price") {
              return {
                name: "Price",
                options: [
                  { value: "0 - 10", label: "0 - 10" },
                  { value: "10 - 20", label: "10 - 20" },
                  { value: "20 - 30", label: "20 - 30" },
                  { value: "30 - 40", label: "30 - 40" },
                  { value: "40 - 50", label: "40 - 50" },
                  { value: "50 - 60", label: "50 - 60" },
                  { value: "60 - 70", label: "60 - 70" },
                  { value: "70 - 80", label: "70 - 80" },
                  { value: "80 - 90", label: "80 - 90" },
                  { value: "90 - 99999999", label: "90 - 1000+" },
                ],
              };
            } else if (filterOption.name === "Position") {
              return {
                name: "Position",
                options: [
                  { value: "-99999999 - -901", label: "-901 - -99999999" },
                  { value: "-900 - -801", label: "-801 - -900" },
                  { value: "-800 - -701", label: "-701 - -800" },
                  { value: "-700 - -601", label: "-601 - -700" },
                  { value: "-600 - -501", label: "-501 - -600" },
                  { value: "-500 - -401", label: "-401 - -500" },
                  { value: "-400 - -301", label: "-301 - -400" },
                  { value: "-300 - -201", label: "-201 - -300" },
                  { value: "-200 - -101", label: "-101 - -200" },
                  { value: "-100 - -0.1", label: "-0.1 - -100" },
                  { value: "0 - 0", label: "0 - 0" },
                  { value: "0 - 100", label: "0 - 100" },
                  { value: "101 - 200", label: "101 - 200" },
                  { value: "201 - 300", label: "201 - 300" },
                  { value: "301 - 400", label: "301 - 400" },
                  { value: "401 - 500", label: "401 - 500" },
                  { value: "501 - 600", label: "501 - 600" },
                  { value: "601 - 700", label: "601 - 700" },
                  { value: "701 - 800", label: "701 - 800" },
                  { value: "801 - 900", label: "801 - 900" },
                  { value: "901 - 99999999", label: "901 and over" },
                ],
              };
            }
            return filterOption;
          })
        );
      } else {
        throw new Error("An error occurred while fetching filters data");
      }
    } catch (error) {
      toast.remove();
      toast.error("An error occurred while fetching filters data");
    }
  }

  async function fetchTradesData(filters?: any, queries?: any) {
    setIsLoading(true);

    // Check if there are any filters
    if (filters && Object.keys(filters).length > 0) {
      setJournal([]); // Clear the journal if there are filters
      queries = {
        ...queries,
        all: "true", // Set queries to include { all: "true" }
      };
    }
    try {
      const response = await JournalService.tradesJournalControllerFindTrades({
        filters: filters || {},
        queries: {
          ...queries,
          page: currentPage,
        },
      });

      if (response.success) {
        const scrappedTrades = response.data.data.map(
          (trade: any): JournalType => ({
            date: trade.date,
            tradeCount: trade.tradeCount,
            totalReturn: trade.totalReturn,
            totalCommission: trade.totalCommission,
            totalNetReturn: trade.totalNetReturn,
            maxMFE: trade.maxMFE,
            maxMAE: trade.maxMAE,
            winPercentage: trade.winPercentage,
            profitFactor: trade.profitFactor,
            trades: trade.trades,
            journal: trade.journal,
          })
        );

        // Append new records without duplicating
        setJournal((prevJournal) => {
          const existingTradeDates = new Set(
            prevJournal.map((item) => item.date)
          ); // Store existing trade dates
          const newTrades = scrappedTrades.filter(
            (trade: any) => !existingTradeDates.has(trade.date)
          ); // Filter out duplicates
          return [...prevJournal, ...newTrades]; // Append new trades
        });

        setTotalPages(response.data.pages);
        setHasNextPage(response.data.hasNextPage);
      }
    } catch (error) {
      toast.remove();
      toast.error("An error occurred while fetching data");
    } finally {
      setIsLoading(false);
      setIsFetchingMore(false);
    }
  }

  // Using react-intersection-observer for infinite scroll
  const { ref: loaderRef, inView } = useInView({
    threshold: 1.0,
    onChange: (inView) => {
      if (inView && !isFetchingMore && currentPage < totalPages) {
        setIsFetchingMore(true);
        setCurrentPage(currentPage + 1); // Fetch the next page
      }
    },
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchTradesData(generateApiPayloadFilters(allSelectedFilters), {
        page: currentPage,
      });
    }
  }, [inView, currentPage]);

  return (
    <>
      <div className="dark:text-white">
        <h1 className="text-3xl font-semibold">Journal</h1>

        <Menu
          setToggleCalendar={setToggleCalendar}
          toggleCalendar={toggleCalendar}
          filtersOptions={filtersOptions}
          allSelectedFilters={allSelectedFilters}
          handleTagRemove={handleTagRemove}
          isFilterSelected={isFilterSelected}
          setIsFilterSelected={setIsFilterSelected}
          setAllSelectedFilters={setAllSelectedFilters}
        />
        {!isLoading && journal && journal.length === 0 && (
          <div className="flex justify-center mt-4 items-center">
            No journal found
          </div>
        )}

        {!toggleCalendar ? (
          <div className="grid grid-cols-7 gap-3 py-6">
            {journal.map((item: JournalType, index: number) => (
              <CalendarBox
                key={index}
                setOpenSidePannel={setOpenSidePannel}
                journal={item}
                setViewJournal={setViewJournal}
              />
            ))}
          </div>
        ) : (
          <div className="flex flex-col gap-3 py-6">
            {journal.map((item: JournalType, index: number) => (
              <CalendarWithNotes
                key={index}
                setOpenSidePannel={setOpenSidePannel}
                journal={item}
                setViewJournal={setViewJournal}
              />
            ))}
          </div>
        )}
      </div>

      {/* Loader for infinite scrolling */}
      <div ref={loaderRef} className="flex w-full items-center justify-center">
        {isLoading && (
          <div className="flex w-full items-center justify-center py-3 min-w-[50px]">
            <TailSpin
              width={"28px"}
              height={"28px"}
              color={theme === "light" ? "#36454F" : "#3A6FF8"}
            />
          </div>
        )}
      </div>

      {openSidePannel && (
        <JournalModal
          openSidePannel={openSidePannel}
          setOpenSidePannel={() => {
            setOpenSidePannel(false);
            setViewJournal({} as JournalType);
          }}
          viewJournal={viewJournal}
        />
      )}
    </>
  );
};

export default Journal;
