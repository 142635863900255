import React from "react";

interface CheckboxProps {
  checked: boolean;
  onChange: (checked: boolean, e: any) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange }) => (
  <div
    className={`w-5 h-5 rounded border-2 text-center cursor-pointer transition-colors duration-200 ease-in-out flex items-center justify-center
                ${
                  checked
                    ? "dark:bg-dark-border border-black dark:border-dark-border"
                    : "dark:bg-black bg-white border-black dark:border-dark-border hover:bg-primary/10"
                }`}
    onClick={(e) => onChange(!checked, e)}
  
  >
    {checked && (
      <svg className="w-3 h-3 text-primary-foreground" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
        />
      </svg>
    )}
  </div>
);

export default Checkbox;
