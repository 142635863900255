import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useTheme } from "../../../../context/ThemeContext";

type AppProps = {
  value?: string;
  onChange?: (content: string) => void;
  handleInput?: () => void;
  height?: number;
  toolbar?: string;
  width?: string;
  editor?: boolean;
  toolbar_location?: string;
  inline_toolbar?: string;
  changeContent?: (textContent: string, htmlContent: string) => void;
};

const App: React.FC<AppProps> = ({
  value,
  onChange = () => {},
  height,
  toolbar,
  width,
  changeContent = () => {},
}) => {
  const editorRef = useRef<any>(null);
  const { theme } = useTheme();
  const mainColor = theme === "light" ? "white" : "#272727";

  return (
    <>
      <Editor
        onInit={(event, editor) => {
          editorRef.current = editor;
        }}
        tinymceScriptSrc={'/tinymce/tinymce.min.js'}
        value={value}
        onEditorChange={(html, editor) => {
          const jsonContent = editor.getContent({ format: "text" });
          onChange(html);
          changeContent(jsonContent, html);
        }}
        init={{
          menubar: false,
          statusbar: false,
          toolbar_mode: "wrap",
          noneditable_class: "nonedit",
          editable_class: "editable",

          placeholder: "Type something here...",
          height: height || 400,
          toolbar: toolbar || " mergetags bold italic underline blockquote ",
          width: width || "100%",

          plugins:
            "preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap emoticons",
          image_advtab: true,
          link_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          image_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          image_class_list: [
            { title: "None", value: "" },
            { title: "Some class", value: "class-name" },
          ],
          importcss_append: true,
          file_picker_callback: (callback, _, meta) => {
            if (meta.filetype === "file") {
              callback("https://www.google.com/logos/google.jpg", {
                text: "My text",
              });
            }
            if (meta.filetype === "image") {
              callback("https://www.google.com/logos/google.jpg", {
                alt: "My alt text",
              });
            }
            if (meta.filetype === "media") {
              callback("movie.mp4", {
                source2: "alt.ogg",
                poster: "https://www.google.com/logos/google.jpg",
              });
            }
          },
          templates: [
            {
              title: "New Table",
              description: "creates a new table",
              content:
                '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
            },
            {
              title: "Starting my story",
              description: "A cure for writers block",
              content: "Once upon a time...",
            },
            {
              title: "New list with dates",
              description: "New List with dates",
              content:
                '<div class="mceTmpl"><span class="cdate">cdate</span><br><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
            },
          ],
          template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
          template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
          image_caption: true,
          paste_data_images: true,
          contextmenu: "link | paste |  image table",
          skin: "oxide",
          content_css: "default",
          content_style: `
            body { font-family:Helvetica,Arial,sans-serif; font-size:16px;   background-color: ${mainColor}; color: ${
            mainColor === "#272727" ? "white" : "black"
          } } 
          body[data-mce-placeholder]::before { 
  content: attr(data-mce-placeholder) !important; 
  color: ${
    mainColor === "#272727" ? "white" : "grey"
  } !important; /* Customize placeholder color */
  opacity: 0.7 !important; /* Adjust opacity if needed */
}

            .tag {
              display:inline;
              background-color:purple;
              color:#FFF;
              padding:4px 10px;
              border-radius: 0.5em;

          }
          `,
          setup: (editor) => {
            // Apply custom toolbar styling via CSS
            editor.on("init", () => {
              const toolbarElements = document.querySelectorAll(".tox-tinymce");
              toolbarElements.forEach((element: any) => {
                element.style.backgroundColor = mainColor;
                element.style.borderColor = mainColor;
              });
              const toolbarBGElements =
                document.querySelectorAll(".tox-toolbar");
              toolbarBGElements.forEach((element: any) => {
                element.style.backgroundColor = mainColor;
                // element.style.borderColor = mainColor;
              });

              const toolbarElements1 =
                document.querySelectorAll(".tox-editor-header");
              toolbarElements1.forEach((element: any) => {
                element.style.backgroundColor = mainColor;
                // element.style.borderColor = mainColor;
              });
              const observer = new MutationObserver((mutationsList) => {
                mutationsList.forEach((mutation) => {
                  const icons = document.querySelectorAll(".tox-tbtn");
                  if (icons.length) {
                    icons.forEach((element: any) => {
                      element.style.backgroundColor =
                        mainColor === "#272727" ? "#66B2B2" : "#ffffff";
                    });
                    observer.disconnect(); // Stop observing once the elements are found
                  }
                });
              });

              observer.observe(document.body, {
                childList: true,
                subtree: true,
              });
            });
          },
          link_context_toolbar: true,
        }}
      />
    </>
  );
};

export default App;
