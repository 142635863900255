import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import AsyncButton from "../../../../../../../../components/AsyncButton";
import { MultiSelect } from "../../../../../../../../components/FormFields/FormFields";
import { useAppContext } from "../../../../../../../../context/AppContext";

const multiSelectSchema = yup.object().shape({
  label: yup.string().required("Label is required"),
  value: yup.string().required("Value is required"),
});

const portfolioSelectionForTradesSchema = yup.object().shape({
  selectedPortfolio: multiSelectSchema.required("Portfolio is required"),
});

type SelectedPortfolioForTradesType = {
  selectedPortfolio: multiSelect;
};

function ReassignTrades({
  onClose,
  isDeleteTradesLoading,
  portfolioId,
  deletePortfolioPlusTrades,
}: {
  onClose: () => void;
  isDeleteTradesLoading: boolean;
  portfolioId?: string;
  deletePortfolioPlusTrades?: (reassignPortfolioId: string) => void;
}) {
  const { allPortfolios } = useAppContext();

  const [selectedPortfolioForTrades] = useState<SelectedPortfolioForTradesType>(
    {
      selectedPortfolio: { label: "Select Portfolio", value: "" },
    }
  );

  // Filtering over allPortfolios
  const [availablePortfolios] = useState<multiSelect[]>(
    allPortfolios
      .map((p) => p._id !== portfolioId && { label: p.name, value: p._id })
      .filter((p) => typeof p !== "boolean")
  );

  function handleSubmit(formData: SelectedPortfolioForTradesType) {
    if(deletePortfolioPlusTrades){
      
      deletePortfolioPlusTrades(formData.selectedPortfolio.value);
    }
  }

  const formik = useFormik({
    initialValues: selectedPortfolioForTrades,
    validationSchema: portfolioSelectionForTradesSchema,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <>
      <p className="text-light-primary dark:text-dark-secondary">
        Please, select the portfolio to reassign your trades
      </p>
      <form onSubmit={formik.handleSubmit} className="pb-2">
        <MultiSelect
          name="selectedPortfolio"
          // label="Portfolios"
          options={availablePortfolios}
          value={formik?.values?.selectedPortfolio}
          className={"focus-within:!border-primary"}
          error={
            formik?.touched?.selectedPortfolio &&
            formik?.errors?.selectedPortfolio?.value &&
            "Portfolio is required for reassigning trades"
          }
          placeholder="Select Portfolio"
          width="w-full"
          isMulti={false}
          clearable={
            formik?.values?.selectedPortfolio?.value !== "" ? true : false
          }
          searchable={true}
          labelInField={false}
          borderClass=""
          borderOnFocusClass="rounded-lg border-none dark:bg-dark-inputBackground"
          formikHandleChange={(selected: { label: string; value: string }) => {
            const selectedValues = selected;

            if (selected === null) {
              formik.setFieldValue("selectedPortfolio", {
                label: "Select Portfolio",
                value: "",
              });
            } else {
              formik.setFieldValue("selectedPortfolio", selectedValues);
            }
          }}
          isDisabled={false}
        />
        <div className="flex justify-between items-center mt-5">
          <button
            onClick={() => onClose()}
            type="button"
            className="bg-light-lightGray dark:bg-[#66b2b244] text-light-foreground dark:text-dark-border py-2 px-5 rounded hover:opacity-80 transition-opacity"
          >
            Cancel
          </button>
          <AsyncButton
            loading={isDeleteTradesLoading ? true : undefined}
            type="submit"
            className="bg-light-foreground text-white dark:bg-dark-border hover:opacity-80 transition-opacity py-2 px-5 rounded-md cursor-pointer"
          >
            Save
          </AsyncButton>
        </div>
      </form>
    </>
  );
}

export default ReassignTrades;
