import { ReactNode } from "react";
import { TailSpin } from "react-loader-spinner";

export const AsyncButton = ({
  children,
  loading,
  type,
  disabled,
  className,
  buttonClass,
  color,
  loadingMessage,
  onClick,
  ...props
}: {
  children: ReactNode;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  loadingMessage?: string;
  className?: string;
  buttonClass?: string;
  color?: string;
  onClick?: () => void;
  [key: string]: any;
}) => {
  return (
    <button
      type={type ? type : "button"}
      disabled={disabled || loading ? true : false}
      className={`${buttonClass} ${className} ${
        loading && "hover:opacity-100 !shadow-none hover:scale-100"
      }`}
      onClick={onClick}
      {...props}
    >
      {!loading ? (
        <>{children}</>
      ) : (
        <>
          <div className="flex justify-center items-center gap-3">
            {loadingMessage && (
              <p className="font-medium dark:text-dark-border text-center">
                {loadingMessage}
              </p>
            )}
            <TailSpin
              visible={true}
              height="25"
              width="25"
              color={color ? color : "#FFFFFF"}
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </>
      )}
    </button>
  );
};
