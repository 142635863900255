import axios from "axios";
import toast from "react-hot-toast";
export type StepType = "Confirm" | "Confirmation" | "Reassign your trades";
function DeleteTrades({
  onClose,
  trades,
  setIsOpen,
  setSelectedRows,
  fetchTradesData,
}: {
  onClose: () => void;
  fetchTradesData: () => void;
  setIsOpen: () => void;
  setSelectedRows: () => void;
  trades: string[];
  jumpToStep?: StepType;
}) {
  async function handleTradeDelete(id: string[]) {
    try {
      const deleted: any = await axios.delete(`/v1/api/trades`, {
        data: { tradeIds: id },
      });
      if (deleted.success) {
        toast.success("Trade deleted");
        fetchTradesData();
        setIsOpen();
        setSelectedRows();
      }
    } catch (error: any) {
      setIsOpen();
      setSelectedRows(); 
      toast.remove();
      toast.error("An error occurred while deleting trade");
    }
  }
  return (
    <>
      <div className="relative bg-grayMain dark:bg-dark-background w-full lg:p-8 p-5 rounded-lg">
        <div className="w-full absolute bg-light-foreground text-white dark:bg-dark-border top-0 right-0 left-0 p-2">
          <h1>Confirm</h1>
        </div>
        <div className="mt-8 space-y-5">
          <p className="text-light-primary dark:text-dark-secondary">
            Are you sure you want to delete the trades ?
          </p>
          <div className="flex justify-between items-center">
            <button
              onClick={() => onClose()}
              className="bg-light-lightGray dark:bg-[#66b2b244] text-light-foreground dark:text-dark-border py-2 px-5 rounded hover:opacity-80 transition-opacity"
            >
              Cancel
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleTradeDelete(trades);
              }}
              className="bg-light-foreground text-white dark:bg-dark-border hover:opacity-80 transition-opacity py-2 px-5 rounded-md cursor-pointer"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteTrades;
