import React, { useEffect, useState } from "react";
import { images } from "../../../../assets/images";
import { useTheme } from "../../../../context/ThemeContext";
import SidePanel from "../../../../components/SidePanel/SidePanel";
import TinyMCE from "../components/TinyMCE";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ImagesComponent from "../components/ImageComponent";
import { JournalService } from "../../../../../generated/services/JournalService";
import { JournalType, ViewJournal } from "../Journal";
import { formatNumber } from "../../../../utils/utils";
import TradesLogTable from "../../trades/tradeslog/components/TradesLogTable";
import moment from "moment";
import PreTradingQuestion from "./PreTradingQuestions";

const PreTradingQuestions = [
  "How well-rested do you feel today?",
  "How focused are you feeling right now?",
  "What is your emotional state?",
  "Do you have any personal or external distractions?",
  "How confident are you in your strategy for today's trading session?",
  "Do you feel mentally clear and focused right now?",
  "Are you concerned about any specific market events or trends today?",
  "How well-prepared do you feel for potential volatility in the market?",
  "How would you rate your overall enthusiasm for trading today?",
  "How confident are you in your ability to handle emotional stress today?",
];

const PostTradingQuestions = [
  "How satisfied are you with your trading decisions today?",
  "How emotionally composed did you feel during trades?",
  "Did you feel overwhelmed or in control during market fluctuations?",
  "How would you rate your overall mood after today’s session?",
  "How well did you stick to your trading plan today?",
  "How well did you stick to your trading plan today?",
  "How effectively did you manage your emotions during losing trades?",
  "Do you feel you learned something valuable from today's session?",
  "How would you rate your overall satisfaction with today's trading performance?",
  "How emotionally exhausted do you feel after today’s session?",
];
const JournalModal = ({
  setOpenSidePannel,
  openSidePannel,
  viewJournal,
}: {
  setOpenSidePannel: (value: boolean) => void;
  openSidePannel: boolean;
  viewJournal: JournalType;
}) => {
  const { theme } = useTheme();
  const [editorContent, setEditorContent] = useState<string>("");
  const [journalData, setJournalData] = useState<ViewJournal>();
  const [screenShots, setScreenShots] = useState<{
    path: string;
    imageSize: number;
  }>({} as { path: string; imageSize: number });
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [showPreTradingQuestion, setShowPreTradingQuestion] =
    useState<boolean>(false);
  const [showPostTradingQuestion, setShowPostTradingQuestion] =
    useState<boolean>(false);

  // Auto-save journal on typing
  useEffect(() => {
    if (!isTyping) return;

    const saveJournal = async () => {
      try {
        if (journalData?._id) {
          // Update existing journal
          await JournalService.tradesJournalControllerUpdateJournal(
            journalData._id,
            {
              notes: editorContent,
              // images: screenShots,
            }
          );
        } else {
          // Create new journal
          const response =
            await JournalService.tradesJournalControllerCreateJournal({
              date: viewJournal.date,
              notes: editorContent,
              images: screenShots.path ? [screenShots.path] : undefined,
              imageSize: screenShots?.imageSize,
            });
          setJournalData(response.data); // Set the created journal data
        }
      } catch (err) {
        console.error("Error saving journal:", err);
      }
    };

    const timer = setTimeout(() => {
      saveJournal();
      setIsTyping(false);
    }, 1000); // Call API 1 second after the user stops typing

    return () => clearTimeout(timer);
  }, [editorContent, screenShots, journalData, isTyping, viewJournal.date]);

  useEffect(() => {
    if (!viewJournal.date) return; // Avoid calling the API if date is not provided
    fetchJournal();
  }, [viewJournal.date]);
  const fetchJournal = async () => {
    try {
      const response =
        await JournalService.tradesJournalControllerGetJournalByDate(
          viewJournal.date
        );
      if (response.success) {
        setJournalData(response.data);
        setEditorContent(response?.data?.notes);
      }
    } catch (err) {
    } finally {
    }
  };

  const calculateTotalScore = (ratingsArray: number[]) => {
    return ratingsArray.reduce((total, rating) => total + rating, 0);
  };

  // Function to parse ratings from a string format like "Question: 3"
  const parseRatings = (questionsArray: string[]) => {
    return questionsArray.map((question: string) => {
      const [, rating] = question.split(": ");
      return parseInt(rating, 10);
    });
  };

  // Calculate total PRS and ERS
  const PRS = calculateTotalScore(
    parseRatings(journalData?.preTradingQuestions || [])
  );
  const ERS = calculateTotalScore(
    parseRatings(journalData?.postTradingQuestions || [])
  );

  return (
    <div>
      <SidePanel
        closeModal={() => setOpenSidePannel(false)}
        openPanel={openSidePannel}
      >
        <div className="p-6 bg-[#E9E8E8] dark:bg-dark-backgroundMain">
          {/* Header */}
          <div className="flex gap-3 items-center mb-4">
            <div className="flex space-x-2">
              <button
                onClick={() => {
                  setShowPreTradingQuestion(true);
                }}
                className="px-3 dark:text-dark-border py-1 flex bg-white dark:bg-dark-background rounded-md shadow"
              >
                <span className="mr-2 ">
                  {images.PreTradeIcon(
                    `w-6 h-6  ${
                      theme === "light" ? "fill-black" : "fill-[#66B2B2]"
                    }`
                  )}
                </span>{" "}
                Pre-Trading Questions
              </button>
              <button
                onClick={() => {
                  setShowPostTradingQuestion(true);
                }}
                className="px-3 dark:text-dark-border py-1 flex bg-white dark:bg-dark-background rounded-md shadow"
              >
                <span className="mr-2 ">
                  {images.PostTradeIcon(
                    `w-6 h-6  ${
                      theme === "light" ? "fill-black" : "fill-[#66B2B2]"
                    }`
                  )}
                </span>{" "}
                Post-Trading Questions
              </button>
            </div>
            <div className="flex space-x-2">
              <span
                data-tooltip-id="my-tooltip-3"
                className="px-2 py-1 cursor-pointer bg-light-foreground dark:bg-dark-border dark:text-dark-background text-white rounded-md"
              >
                PRS: {PRS}
                <ReactTooltip
                  id="my-tooltip-3"
                  place="top"
                  // variant="info"
                  className="!w-[20rem] !cursor-pointer !text-xs !bg-light-foreground dark:!bg-dark-border"
                  content="Psychological Readiness Score (PRS) : The PRS measures your mental and emotional preparedness before starting a trading session, helping assess focus, restfulness, and overall mindset."
                />
              </span>
              <span
                data-tooltip-id="my-tooltip-4"
                className="px-2 py-1 cursor-pointer bg-light-foreground dark:bg-dark-border dark:text-dark-background text-white rounded-md"
              >
                ERS: {ERS}
                <ReactTooltip
                  id="my-tooltip-4"
                  place="top"
                  className="!w-[20rem] !cursor-pointer !bg-light-foreground  !text-xs dark:!bg-dark-border"
                  content="Emotional Resilience Score (ERS) : The ERS evaluates how well you handled emotional pressures and market volatility during the session, reflecting their ability to stay composed and make sound decisions."
                />
              </span>
            </div>
            <div
              onClick={() => {
                setOpenSidePannel(false);
              }}
              className="ml-auto cursor-pointer bg-white dark:bg-dark-background dark:border-[1px] dark:border-white  p-2 rounded-md"
            >
              <svg
                width="15px"
                height="15px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                className={`dark:fill-white`}
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </div>
          </div>

          {/* Date and Financial Summary */}
          <div className="mb-4">
            <h2 className="text-xl dark:text-white font-medium font-poppins mb-2">
              {moment(viewJournal.date).format("MMM DD, ddd YYYY")}
            </h2>
            <div className="font-poppins gap-4 p-4 px-6 rounded-xl dark:bg-dark-background bg-white">
              <div>
                <p className="text-lg mb-4  text-[#14B8A6]">
                  {" "}
                  {`$ ${formatNumber(
                    Number(viewJournal?.totalReturn?.toFixed(2))
                  )}`}
                </p>

                <div className="grid grid-cols-6 gap-4">
                  <div className="flex justify-start items-center">
                    <span className="text-xs text-[#666666]">Returns $</span>
                  </div>
                  <div className="flex justify-start items-center">
                    <span className="text-xs text-[#929292]">{`$ ${formatNumber(
                      Number(viewJournal?.totalReturn?.toFixed(2))
                    )}`}</span>
                  </div>

                  <div className="flex justify-start items-center">
                    <span className="text-xs text-[#666666]">Commissions</span>
                  </div>
                  <div className="flex justify-start items-center">
                    <span className="text-xs text-[#929292]">
                      {" "}
                      {`$ ${formatNumber(
                        Number(viewJournal?.totalCommission?.toFixed(2))
                      )}`}
                    </span>
                  </div>

                  <div className="flex justify-start items-center">
                    <span className="text-xs text-[#666666]">Net Return $</span>
                  </div>
                  <div className="flex justify-start items-center">
                    <span className="text-xs text-[#929292]">
                      {" "}
                      {`$ ${formatNumber(
                        Number(viewJournal?.totalNetReturn?.toFixed(2))
                      )}`}
                    </span>
                  </div>

                  <div className="flex justify-start items-center">
                    <span className="text-xs text-[#666666]">Win %</span>
                  </div>
                  <div className="flex justify-start items-center">
                    <span className="text-xs text-[#929292]">
                      {" "}
                      {`${viewJournal.winPercentage?.toFixed(2)}%`}
                    </span>
                  </div>

                  <div className="flex justify-start items-center">
                    <span className="text-xs text-[#666666]">
                      Profit Factor
                    </span>
                  </div>
                  <div className="flex justify-start items-center">
                    <span className="text-xs text-[#929292]">
                      {" "}
                      {` ${viewJournal.profitFactor?.toFixed(2)}`}
                    </span>
                  </div>

                  <div className="flex justify-start items-center">
                    <span className="text-xs text-[#666666]">Trades</span>
                  </div>
                  <div className="flex justify-start items-center">
                    <span className="text-xs text-[#929292]">
                      {" "}
                      {` ${viewJournal.tradeCount}`}
                    </span>
                  </div>

                  <div className="flex justify-start items-center">
                    <span className="text-xs text-[#666666]">MFE</span>
                  </div>
                  <div className="flex justify-start items-center">
                    <span className="text-xs text-[#929292]">
                      {" "}
                      {` ${
                        viewJournal?.maxMFE === null
                          ? "-"
                          : "$" +
                            formatNumber(
                              Number(viewJournal?.maxMFE?.toFixed(2))
                            )
                      }`}
                    </span>
                  </div>

                  <div className="flex justify-start items-center">
                    <span className="text-xs text-[#666666]">MAE</span>
                  </div>
                  <div className="flex justify-start items-center">
                    <span className="text-xs text-[#929292]">
                      {" "}
                      {`${
                        viewJournal?.maxMAE === null
                          ? "-"
                          : "$" +
                            formatNumber(
                              Number(viewJournal?.maxMAE?.toFixed(2))
                            )
                      }`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Daily Notes */}
          <div className=" mb-4">
            <h2 className="text-xl dark:text-white  font-medium font-poppins mb-2">
              Daily Notes
            </h2>
            <TinyMCE
              height={300}
              value={editorContent}
              changeContent={(changeContent, html) => {
                setEditorContent(html);
                setIsTyping(true);
              }}
              editor={false}
              inline_toolbar="rewrite sendCommand"
              toolbar=" fontfamily fontsize  bold italic underline blockquote   forecolor backcolor  alignleft aligncenter alignright alignjustify "
            />
          </div>

          {/* Add Images */}
          <div className="mb-4">
            <h2 className="text-xl dark:text-white  font-medium font-poppins mb-2">
              Add Images
            </h2>

            <div className="flex items-center space-x-4 font-poppins gap-4 p-4 dark:bg-dark-background   px-6 rounded-xl bg-white">
              <ImagesComponent
                journalData={journalData}
                setScreenShots={(images: any) => {
                  setScreenShots(images);
                  setIsTyping(true); // Trigger save after images are uploaded
                }}
                screenShot={journalData?.images || []}
                fetchJournal={fetchJournal}
              />
            </div>
          </div>

          {/* Trades */}
          <div className="mb-4">
            <div className="flex items-center gap-1">
              <h2 className="text-xl dark:text-white  font-medium font-poppins mb-2 mr-2">
                Trades
              </h2>
              <div>
                <div data-tooltip-id="my-tooltip-2">
                  <span>
                    {images.InfoIcon(
                      "w-8 h-8 mt-1 dark:fill-dark-border cursor-pointer fill-gray-400"
                    )}
                  </span>
                </div>
                <ReactTooltip
                  id="my-tooltip-2"
                  place="top"
                  variant="info"
                  content="Trades of Journal"
                />
              </div>
            </div>

            <TradesLogTable
              props={
                viewJournal?.trades && {
                  trades: viewJournal?.trades,
                  className: "!w-full",
                }
              }
              hideFilter
            />
          </div>
        </div>
      </SidePanel>
      {showPreTradingQuestion && (
        <SidePanel
          closeModal={() => setShowPreTradingQuestion(false)}
          openPanel={showPreTradingQuestion}
        >
          <PreTradingQuestion
            closeModal={setShowPreTradingQuestion}
            questions={PreTradingQuestions}
            journal={viewJournal}
            journalData={journalData}
            setJournalData={setJournalData}
            title={"Pre-Trading Questions"}
          />
        </SidePanel>
      )}
      {showPostTradingQuestion && (
        <SidePanel
          closeModal={() => setShowPostTradingQuestion(false)}
          openPanel={showPostTradingQuestion}
        >
          <PreTradingQuestion
            closeModal={setShowPostTradingQuestion}
            questions={PostTradingQuestions}
            journal={viewJournal}
            journalData={journalData}
            setJournalData={setJournalData}
            title={"Post-Trading Questions"}
          />
        </SidePanel>
      )}
    </div>
  );
};

export default JournalModal;
