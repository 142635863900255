function StatusCell({ info }: { info: any }) {
  const val: string = info.getValue() as string;
  let style = "";
  switch (val) {
    case "open":
      style = "bg-light-secondary dark:bg-dark-offWhite";
      break;
    case "win":
      style = "bg-dark-border";
      break;
    case "loss":
      style = "bg-delRed";
  }

  return (
    <div
      className={`w-10 h-6 rounded-[2px] flex justify-center items-center text-xs text-white dark:text-black ${style}`}
    >
      <p>{val.toUpperCase()}</p>
    </div>
  );
}

export default StatusCell;
