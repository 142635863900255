import axios from "axios";
import toast from "react-hot-toast";

let requestInterceptorId: number | null = null;
let responseInterceptorId: number | null = null;

export const API_URL = `${import.meta.env.VITE_BASE_API}`;

axios.defaults.baseURL = API_URL;

export function setupAxios(token?: string) {
  // Eject existing interceptors if they exist
  if (requestInterceptorId !== null) {
    axios.interceptors.request.eject(requestInterceptorId);
  }
  if (responseInterceptorId !== null) {
    axios.interceptors.response.eject(responseInterceptorId);
  }

  // Add new request interceptor
  requestInterceptorId = axios.interceptors.request.use(
    async (config) => {
      config.headers.Authorization = `Bearer ${JSON.parse(
        localStorage.getItem("currentUserToken")!
      )}`;
      return config;
    },
    (err) => Promise.reject(err)
  );

  // Add new response interceptor
  responseInterceptorId = axios.interceptors.response.use(
    (response) => {
      return { ...response.data, ...response };
    },
    function (error) {
      const message = error.response?.data?.message;
      if (error.response) {
        if (error.response?.status === 401) {
          // Handle unauthorized error
          localStorage.removeItem("userLogin");
          localStorage.removeItem("currentUserToken");
          // disconnectSocket();
          // Get the current origin (protocol + domain)
          const origin = window.location.origin;

          // Construct the full URL to the /login page
          const loginUrl = `${origin}/login`;

          // Redirect to the /login page
          window.location.href = loginUrl;
        } else if (error.response?.status === 404) {
          // Handle not found error
        } else if (error.response?.status === 409) {
          // Handle conflict error
        } else {
          toast.remove();
          toast.error(message || "Something Went Wrong! Please try again");
        }
      }
      return Promise.reject(error);
    }
  );
}
