import moment from "moment";
import { images } from "../../../../assets/images";
import { JournalType } from "../Journal";
import { formatNumber } from "../../../../utils/utils";

const CalendarBox = ({
  key,
  setOpenSidePannel,
  journal,
  setViewJournal,
}: {
  key: number;
  setOpenSidePannel: (value: boolean) => void;
  journal: JournalType;
  setViewJournal: (value: JournalType) => void;
}) => {
  return (
    <div key={key} className="bg-white dark:bg-dark-background rounded-lg">
      <div className="flex items-end justify-between border-b p-2">
        <h3 className="text-[9px]">
          {moment(journal.date).format("MMM DD, ddd YYYY")}
        </h3>

        <div
          className="text-[7px] text-[#36454F] dark:text-dark-border flex gap-1 cursor-pointer"
          onClick={() => {
            setOpenSidePannel(true);
            setViewJournal(journal);
          }}
        >
          <span>{images.ViewDetails("")}</span>
          <span>View Details</span>
        </div>
      </div>

      <div className="p-2">
        <div className="pb-1">
          {" "}
          {`$ ${formatNumber(Number(journal?.totalReturn?.toFixed(2)))}`}
        </div>

        <div className="flex justify-between gap-3">
          <div className="flex-1 flex flex-col gap-1">
            <div className="flex items-center justify-between">
              <p className="text-[7px] text-[#36454F] dark:text-white">
                Return$
              </p>
              <p className="text-[7px] text-[#929292] dark:text-white/60">
                {`$ ${formatNumber(Number(journal?.totalReturn?.toFixed(2)))}`}
              </p>
            </div>

            <div className="flex items-center justify-between">
              <p className="text-[7px] text-[#36454F] dark:text-white">
                Comissions
              </p>
              <p className="text-[7px] text-[#929292] dark:text-white/60">
                {" "}
                {`$ ${formatNumber(
                  Number(journal?.totalCommission?.toFixed(2))
                )}`}
              </p>
            </div>

            <div className="flex items-center justify-between">
              <p className="text-[7px] text-[#36454F] dark:text-white">
                Net Return$
              </p>
              <p className="text-[7px] text-[#929292] dark:text-white/60">
                {`$ ${formatNumber(
                  Number(journal?.totalNetReturn?.toFixed(2))
                )}`}
              </p>
            </div>

            <div className="flex items-center justify-between">
              <p className="text-[7px] text-[#36454F] dark:text-white">MFE</p>
              <p className="text-[7px] text-[#929292] dark:text-white/60">
                {" "}
                {` ${
                  journal?.maxMFE === null
                    ? "-"
                    : "$" + formatNumber(Number(journal?.maxMFE?.toFixed(2)))
                }`}
              </p>
            </div>
          </div>

          <div className="flex-1 flex flex-col gap-1">
            <div className="flex items-center justify-between">
              <p className="text-[7px] text-[#36454F] dark:text-white">Win%</p>
              <p className="text-[7px] text-[#929292] dark:text-white/60">
                {`${journal.winPercentage?.toFixed(2)}%`}
              </p>
            </div>

            <div className="flex items-center justify-between">
              <p className="text-[7px] text-[#36454F] dark:text-white">
                Profit Factor
              </p>
              <p className="text-[7px] text-[#929292] dark:text-white/60">
                {" "}
                {` ${journal.profitFactor?.toFixed(2)}`}
              </p>
            </div>

            <div className="flex items-center justify-between">
              <p className="text-[7px] text-[#36454F] dark:text-white">
                Trades
              </p>
              <p className="text-[7px] text-[#929292] dark:text-white/60">
                {" "}
                {` ${journal.tradeCount}`}
              </p>
            </div>

            <div className="flex items-center justify-between">
              <p className="text-[7px] text-[#36454F] dark:text-white">MAE</p>
              <p className="text-[7px] text-[#929292] dark:text-white/60">
                {" "}
                {`${
                  journal?.maxMAE === null
                    ? "-"
                    : "$" + formatNumber(Number(journal?.maxMAE?.toFixed(2)))
                }`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarBox;
