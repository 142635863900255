import { Plan, SubscriptionType } from "../types/types";

export const InitialPlan: Plan = {
    _id: '',
    description: '',
    features: [],
    includes: [],
    name: '',
    popular: false,
    price: '',
    soldOut: false
}

export const InitialSubscriptionValues: SubscriptionType = {
    amount: 0,
    createdAt: '',
    currency: '',
    customerId: '',
    id: '',
    nextPaymentDate: '',
    payment_method_types: [],
    planId: InitialPlan,
    status: '',
    isActive: false,
    userId: '',
    _id: ''
}