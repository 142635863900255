import { RiArrowDropDownLine } from "react-icons/ri";
import FlyoutComponent from "../../../../../../../../../components/FlyoutComponent";
import MultiSelectDropdown from "../../../../../../../../../components/MultiSelectCheckBoxes";
import { SelectedFilterOption } from "../../../TradesLogTable";

function FilterPopup({
  filterName,
  options,
  allSelectedFilters,
  setAllSelectedFilters,
  searchable,
}: {
  filterName: string;
  options: any[];
  allSelectedFilters: SelectedFilterOption[];
  setAllSelectedFilters: React.Dispatch<
    React.SetStateAction<SelectedFilterOption[]>
  >;
  searchable?: boolean;
}) {
  return (
    <>
      <FlyoutComponent
        shown="click"
        top="top-[5px]"
        left="left-[100%]"
        FlyoutContent={() => (
          <MultiSelectDropdown
            filterName={filterName}
            options={options}
            allSelectedFilters={allSelectedFilters}
            setAllSelectedFilters={setAllSelectedFilters}
            searchable={searchable}
          />
        )}
      >
        <button className="flex justify-center items-center px-2 py-1 text-xs bg-transparent text-black border border-black dark:border-dark-border dark:text-dark-border rounded hover:opacity-80 transition-opacity">
          {filterName}{" "}
          <RiArrowDropDownLine
            size={18}
            className="text-black dark:text-dark-border hover:cursor-pointer"
          />
        </button>
      </FlyoutComponent>
    </>
  );
}

export default FilterPopup;
