import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { AuthenticationService } from "../../../../generated/services/AuthenticationService";
import { TailSpin } from "react-loader-spinner";
import toast from "react-hot-toast";

function UserVerified() {
  const [isVerified, setIsVerified] = useState(true);
  const [loading, setLoading] = useState(false);

  const { encodedLink } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    callVerifyAPI();
  }, []);

  async function callVerifyAPI() {
    setLoading(true);
    try {
      const response = await AuthenticationService.authControllerUserVerified(
        encodedLink!
      );
      if (response.success) {
        if (response.status === 409) {
          toast.remove();
          toast.success(response.message);
          navigate("/login");
        } else {
          setIsVerified(true);
        }
      } else {
        throw new Error("An error occurred while verifying user");
      }
    } catch (error: any) {
      toast.remove();
      toast.error(
        error.body.message || "Something went wrong while verifying user"
      );
      console.log(
        error.body.message || "Something went wrong while verifying user"
      );
      setIsVerified(false);
    }
    setLoading(false);
  }

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
          <TailSpin
            width={"54px"}
            height={"54px"}
            color={
              localStorage.getItem("theme") === "light" ? "#36454F" : "#3A6FF8"
            }
          />
        </div>
      ) : isVerified ? (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
          <div className="flex justify-start items-start gap-3 flex-col max-w-sm p-8 bg-white shadow-md rounded-lg">
            <h1 className="text-xl font-semibold text-gray-800">
              Verification Successful
            </h1>
            <p className="text-gray-600">
              Your account has been successfully verified. You can now proceed
              to the login page.
            </p>
            <Link className="btn-secondary" to="/login">
              Go to Login
            </Link>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
          <div className="flex justify-start items-start gap-3 flex-col max-w-sm p-8 bg-white shadow-md rounded-lg">
            <h1 className="text-xl font-semibold text-red-500">
              An Error occurred
            </h1>
            <p className="text-gray-600">
              An error occurred while verifying your account.
            </p>
            <Link className="btn-secondary" to="/login">
              Go to Login
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default UserVerified;
