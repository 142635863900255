/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelPlan } from '../models/CancelPlan';
import type { UpdatePlan } from '../models/UpdatePlan';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SubscriptionsService {
    /**
     * @returns any
     * @throws ApiError
     */
    public static subscriptionControllerGetSubscriptions(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/api/subscription',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    public static subscriptionControllerGetActiveSubscription(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/api/subscription/active',
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static subscriptionControllerCancelSubscription(
        requestBody: CancelPlan,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/api/subscription/cancel',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static subscriptionControllerRenewSubscription(
        requestBody: CancelPlan,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/api/subscription/renew',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static subscriptionControllerUpdateSubscription(
        requestBody: UpdatePlan,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/api/subscription/update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
