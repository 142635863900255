import { useEffect, useState } from "react";
import { useAppContext } from "../../../../../../../../context/AppContext";
import {
  convertToTimezone,
  formatDate,
} from "../../../../../../../../utils/utils";

function DateCell({ info }: { info: any }) {
  const { userData } = useAppContext();
  const [date, setDate] = useState<string>(
    formatDate(info.getValue() as string, userData?.dateFormat)
  );

  useEffect(() => {
    setDate(
      formatDate(
        convertToTimezone(info?.getValue(), userData?.timezone || "UTC"),
        userData?.dateFormat
      )
    );
  }, [userData?.dateFormat, userData, info]);

  return (
    <>
      <div className="text-xs text-black dark:text-white">
        <p>{date}</p>
      </div>
    </>
  );
}

export default DateCell;
