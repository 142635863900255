import React, { useState } from "react";
import { SelectedFilterOption } from "../../pages/app/trades/tradeslog/components/TradesLogTable/TradesLogTable";

type OptionType = {
  value: string;
  label: string;
};

type MultiSelectDropdownProps = {
  filterName: string;
  options: OptionType[];
  allSelectedFilters: SelectedFilterOption[];
  setAllSelectedFilters: React.Dispatch<
    React.SetStateAction<SelectedFilterOption[]>
  >;
  searchable?: boolean;
};

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  filterName,
  options,
  allSelectedFilters,
  setAllSelectedFilters,
  searchable,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleCheckboxChange = (option: SelectedFilterOption) => {
    let updatedSelectedItems: SelectedFilterOption[];
    if (!!allSelectedFilters.find((item) => item.value === option.value)) {
      updatedSelectedItems = allSelectedFilters.filter(
        (item) => item.value !== option.value
      );
    } else {
      updatedSelectedItems = [...allSelectedFilters, option];
    }
    setAllSelectedFilters(updatedSelectedItems);
  };

  const filteredOptions = options.filter((option) =>
    searchable
      ? option?.label?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      : option
  );

  return (
    <div className="relative inline-block text-left">
      <div className="origin-top-right absolute right-0 mt-2 w-56 max-h-52 overflow-y-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
        {searchable && (
          <div className="absolute top-0 right-0 left-0 w-full text-white p-2 border-b flex justify-center items-center bg-white">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full py-1 px-2 text-black outline-none"
            />
          </div>
        )}

        <div
          className={`${searchable ? "pt-16" : "pt-1"} pb-1`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          {filteredOptions.length === 0 && (
            <div className="p-2">
              <p className="text-sm dark:text-black">No options available</p>
            </div>
          )}
          {filteredOptions.map((option) => (
            <div key={option.value} className="flex items-center px-4 py-1">
              <input
                type="checkbox"
                id={option.value}
                checked={
                  !!allSelectedFilters.find(
                    (item) => item.value === option.value
                  )
                }
                onChange={() =>
                  handleCheckboxChange({
                    name: filterName,
                    value: option.value,
                  })
                }
                className="mr-2"
              />
              <label htmlFor={option.value} className="text-sm text-gray-700">
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MultiSelectDropdown;
