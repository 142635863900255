import React, { createContext, useContext, useState, useEffect } from "react";

const defaultState = {
  theme: "light", // Default theme
  toggleTheme: () => {},
};

const ThemeContext = createContext(defaultState);

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    // Check for theme preference in local storage
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    } else {
      // If no theme is stored, check for system theme preference
      const prefersDarkMode =
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches;
      const defaultTheme = prefersDarkMode ? "dark" : "light";
      setTheme(defaultTheme);
      localStorage.setItem("theme", defaultTheme); // Save the default theme based on system preference
    }
  }, []);

  // Change the class on the HTML tag
  useEffect(() => {
    document.documentElement.className = theme;
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
