export const dateFormats = [
  { label: "MM/DD/YY", value: "MM/DD/YY" },
  { label: "DD/MM/YY", value: "DD/MM/YY" },
  { label: "YYYY-MM-DD", value: "YYYY-MM-DD" },
  { label: "DD-MM-YYYY", value: "DD-MM-YYYY" },
  { label: "MM-DD-YYYY", value: "MM-DD-YYYY" },
  { label: "DD MMM YYYY", value: "DD MMM YYYY" },
  { label: "MMM DD, YYYY", value: "MMM DD, YYYY" },
  { label: "DD/MM/YYYY", value: "DD/MM/YYYY" },
  { label: "MM/DD/YYYY", value: "MM/DD/YYYY" },
  { label: "YYYY/MM/DD", value: "YYYY/MM/DD" },
  { label: "YY/MM/DD", value: "YY/MM/DD" },
  { label: "YY-MM-DD", value: "YY-MM-DD" },
];

export const popularLanguages = [
  { label: "English", value: "EN" },
  // { label: "Mandarin Chinese", value: "Mandarin Chinese" },
  { label: "Spanish", value: "ES" },
  // { label: "Hindi", value: "Hindi" },
  // { label: "French", value: "French" },
  // { label: "Standard Arabic", value: "Standard Arabic" },
  // { label: "Bengali", value: "Bengali" },
  // { label: "Russian", value: "Russian" },
  // { label: "Portuguese", value: "Portuguese" },
  // { label: "Indonesian", value: "Indonesian" },
  // { label: "Urdu", value: "Urdu" },
  // { label: "German", value: "German" },
  // { label: "Japanese", value: "Japanese" },
  // { label: "Swahili", value: "Swahili" },
  // { label: "Marathi", value: "Marathi" },
];

// Stocks options
export const marketOptions = [
  { label: "STOCK", value: "stock" },
  { label: "FOREX", value: "forex" },
  { label: "CRYPTO", value: "crypto" },
  { label: "OPTIONS", value: "options" },
  { label: "FUTURES", value: "futures" },
];

export const brokerPlatformOptions = [
  { label: "TraderMetrix", value: "tradermetrix" },
  { label: "Think or Swim", value: "thinkorswim" },
  { label: "Top Step", value: "topStep" },
];

export const spreadOptions = [
  {
    label: "SINGLE",
    value: "single",
  },
  {
    label: "STRADDLE",
    value: "straddle",
  },
  {
    label: "STRANGLE",
    value: "strangle",
  },
  {
    label: "VERTICAL",
    value: "vertical",
  },
  {
    label: "CALENDAR",
    value: "calendar",
  },
  {
    label: "DIAGONAL",
    value: "diagonal",
  },
  {
    label: "BUTTERFLY",
    value: "butterfly",
  },
  {
    label: "IRON BUTTERFLY",
    value: "iron_butterfly",
  },
  {
    label: "CONDOR",
    value: "condor",
  },
  {
    label: "IRON CONDOR",
    value: "iron_condor",
  },
  {
    label: "STRIP",
    value: "strip",
  },
  {
    label: "STRAP",
    value: "strap",
  },
  {
    label: "COLLAR",
    value: "collar",
  },
  {
    label: "COVERED CALL",
    value: "covered_call",
  },
  {
    label: "COVERED PUT",
    value: "covered_put",
  },
];
