import toast, { Toaster, resolveValue } from "react-hot-toast";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import { IoClose } from "react-icons/io5";

function ToasterMain() {
  return (
    <>
      <Toaster
        toastOptions={{
          duration: 5000,
          success: {
            position: "top-center",
            className: "bg-green-600 text-white",
          },
          error: {
            position: "top-center",
            className: "bg-red-600 text-white",
          },
        }}
      >
        {(t) => (
          <div className="border p-3 shadow-lg rounded flex justify-between items-center gap-8 bg-white dark:bg-dark-background dark:text-white">
            <div className="flex justify-center items-center gap-2">
              {t.type === "error" && (
                <IoIosCloseCircle
                  size={25}
                  className="text-[#EF4444] align-middle"
                />
              )}
              {t.type === "success" && (
                <FaCheckCircle
                  size={21}
                  className="text-[#49CC90] align-middle"
                />
              )}
              <p className="align-middle">{resolveValue(t.message, t)}</p>
            </div>

            <IoClose
              size={19}
              onClick={() => toast.remove(t.id)}
              className={`text-[#CCCCCC] font-extrabold  hover:!text-[#999999] transition-colors cursor-pointer align-middle`}
            />
          </div>
        )}
      </Toaster>
    </>
  );
}
export default ToasterMain;
