/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserProfileUpdateDTO } from '../models/UserProfileUpdateDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserService {
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static userControllerUpdate(
        requestBody: UserProfileUpdateDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/api/user/profile/update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    public static userControllerUser(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/api/user/profile',
        });
    }
    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static userControllerUploadFile(
        formData: {
            file?: Blob;
            path?: string;
            contentType?: string;
            fileSize?: number;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/api/user/upload',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
}
