import { useCallback, useRef, useState, useEffect } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import { TradeType } from "../TradesLogTable";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useNavigate } from "react-router-dom";
import DeleteButton from "./components/DeleteButton";

type TableProps = {
  tableData: TradeType[];
  columns: ColumnDef<TradeType>[];
  handleDeleteTrade: (id: string[]) => void;
  onSelectionChange: (selectedIds: string[]) => void;
  rowSelection?: any;
  setRowSelection?: any;
  hideDelete?: boolean;
};

function Table({
  tableData,
  columns,
  handleDeleteTrade,
  onSelectionChange,
  rowSelection,
  setRowSelection,
  hideDelete = false,
}: TableProps) {
  const navigate = useNavigate();

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
    debugTable: true,
  });

  const { rows } = table.getRowModel();
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: useCallback(() => 33, []),
    getScrollElement: () => tableContainerRef.current,
    overscan: 5,
  });

  useEffect(() => {
    const selectedIds = Object.keys(rowSelection).map(
      (index) => tableData[parseInt(index)]._id
    );
    // todo
    onSelectionChange(selectedIds);
  }, [rowSelection]);

  return (
    <div
      className="relative max-h-[420px] overflow-y-auto"
      ref={tableContainerRef}
    >
      {tableData.length > 0 ? (
        <table className="relative w-full">
          <thead
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
            className="border-b text-xs border-light-darkBorder px-5 py-2 bg-white dark:bg-dark-background pt-4"
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                style={{ display: "flex", width: "100%" }}
              >
                {headerGroup.headers.map((header, idx) => (
                  <th
                    key={header.id}
                    style={{
                      display: "flex",
                      width: header.getSize(),
                    }}
                    className={`${
                      idx !== 0 && "justify-center items-center"
                    } font-normal`}
                  >
                    <div className="flex justify-center items-center w-full cursor-default">
                      <p className="font-normal text-sub_heading_2 flex justify-center w-full">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </p>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {columns.length > 0 ? (
            <tbody
              style={{
                display: "block",
                height: `${rowVirtualizer.getTotalSize()}px`,
                position: "relative",
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const row = rows[virtualRow.index] as Row<TradeType>;
                return (
                  <tr
                    data-index={virtualRow.index}
                    ref={(node) => rowVirtualizer.measureElement(node)}
                    key={row.id}
                    style={{
                      display: "flex",
                      position: "absolute",
                      transform: `translateY(${virtualRow.start}px)`,
                      width: "100%",
                    }}
                    className={`py-1 cursor-pointer hover:brightness-75 transition-all ${
                      virtualRow.index % 2 === 0
                        ? "bg-grayMain dark:bg-dark-backgroundMain"
                        : "bg-white dark:bg-dark-background"
                    }`}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        onClick={() => {
                          if (cell.column.id !== "select") {
                            navigate(`/trades/details/${row.original._id}`);
                          }
                        }}
                        key={cell.id}
                        style={{
                          display: "flex",
                          width: cell.column.getSize(),
                          position: "relative",
                        }}
                        className="py-2 justify-center items-center"
                      >
                        <div className="text-sub_heading_3 overflow-auto max-w-full">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </div>
                      </td>
                    ))}
                    <td
                      className={`ml-auto  justify-center items-center ${
                        hideDelete ? "hidden" : "flex"
                      }`}
                    >
                      <DeleteButton
                        row={row}
                        handleDeleteButton={(id: string) =>
                          handleDeleteTrade([id])
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <div className="w-full flex justify-center items-center p-5 min-h-[200px]">
              <h1 className="text-base 2xl:text-lg font-medium dark:text-white">
                No column selected
              </h1>
            </div>
          )}
        </table>
      ) : (
        <div className="w-full flex justify-center items-center p-5 min-h-[200px]">
          <h1 className="text-xl lg:text-2xl 2xl:text-3xl font-medium dark:text-white">
            No trades available
          </h1>
        </div>
      )}
    </div>
  );
}

export default Table;
