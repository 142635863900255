import React, { ReactNode } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

interface SidePanelProps {
  closeModal: () => void;
  openPanel: boolean;
  user?: any; // Replace `any` with a specific type if you have one
  hideClose?: boolean;
  children: ReactNode;
  title?: string;
  width?: string | number;
  size?: number;
  backdrop?: () => void;
  stopBackdrop?: boolean;
  className?: string;
  buttons?: ReactNode;
}

const SidePanel: React.FC<SidePanelProps> = ({
  closeModal,
  openPanel,
  user,
  hideClose = false,
  children,
  title,
  width,
  size = 30, // Default parameter
  backdrop,
  stopBackdrop = false, // Default parameter
  className = "", // Default parameter
  buttons,
}) => {
  return (
    <>
      {/* <div>disfjoa</div> */}
      <SlidingPane
        isOpen={openPanel}
        from="right"
        className="some-custom-class !p-0"
        overlayClassName="some-custom-overlay-class !p-0 mt-[5rem] z-[40]"
        hideHeader
        
        title=""
        subtitle="Optional subtitle."
        onRequestClose={() => closeModal()}
        width="70%"
        // backdropClicked={() => {
        //   if (backdrop) backdrop();
        //   if (stopBackdrop) {
        //     return;
        //   }
        //   closeModal();
        // }}
      >
        <div className="flex h-full w-full flex-col">
          {title && (
            <div className="z-50 w-full bg-gradient-to-r from-cyan-600 to-teal-400 px-12 py-6">
              <div className="flex w-full items-center justify-between">
                <h1 className="mt-2 text-2xl font-bold text-gray-100">
                  {title}
                </h1>
                {!hideClose && (
                  <span
                    onClick={() => closeModal()}
                    className="animate-slide-out w-2 cursor-pointer rounded-full text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-9 w-9"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 13"
                      />
                    </svg>
                  </span>
                )}
              </div>
            </div>
          )}
          <div
            className={`${
              title && ""
            } h-full overflow-x-hidden scroll-hidden overflow-y-scroll ${
              buttons && "px-8 py-5"
            } ${className}`}
          >
            {children}
          </div>

          {buttons && (
            <div className="bottom-0 flex w-full flex-wrap items-center justify-start gap-5 border-t bg-[#f5f8fa] !px-8 py-5 font-normal">
              {buttons}
            </div>
          )}
        </div>
      </SlidingPane>
    </>
  );
};

export default SidePanel;
