import { AnimatePresence, motion } from "framer-motion";
import { useState, useEffect, useRef } from "react";

interface FlyoutComponentProps {
  children: any;
  FlyoutContent: any;
  shown: "hover" | "click";
  className?: string;
  top?: string;
  left?: string;
}

function FlyoutComponent({
  children,
  FlyoutContent,
  shown,
  top,
  left,
}: FlyoutComponentProps) {
  const [open, setOpen] = useState(false);
  const flyoutRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      flyoutRef.current &&
      !flyoutRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (shown === "click") {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [shown]);

  const handleMouseEnter = () => {
    if (shown === "hover") {
      setOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (shown === "hover") {
      setOpen(false);
    }
  };

  const handleClick = () => {
    if (shown === "click") {
      setOpen((prev) => !prev);
    }
  };

  const handleFlyoutContentClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <div
      ref={flyoutRef}
      className={`relative w-fit h-fit`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <div
        className={`relative z-[9999999999] text-white ${
          shown === "click" ? "cursor-pointer" : ""
        }`}
      >
        {children}
      </div>
      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            style={{ translateX: "-68%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className={`absolute ${left ? left : "left-1/2"} ${
              top ? top : "top-[58px]"
            } bg-white text-black rounded z-[9999]`}
            onClick={handleFlyoutContentClick}
          >
            <div className="absolute -top-6 left-0 right-0 h-6 bg-transparent" />
            <FlyoutContent />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default FlyoutComponent;
