import {
  ReactNode,
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { TailSpin } from "react-loader-spinner";
import { setupAxios } from "../setupAxios";
import { useNavigate } from "react-router-dom";
import { AuthenticationService } from "../../generated/services/AuthenticationService";
import toast from "react-hot-toast";
import { io, Socket } from "socket.io-client";
import { UserService } from "../../generated/services/UserService";
import { PortfolioService } from "../../generated/services/PortfolioService";
import { PortfolioDto } from "../../generated/models/PortfolioDto";
import { SocketCommands, SocketEvents, SocketListeners } from "../socketEnums";
import { SubscriptionsService } from "../../generated/services/SubscriptionsService";
import { SubscriptionType } from "../types/types";
import { InitialSubscriptionValues } from "../constants/constants";

let socketUrl = import.meta.env.VITE_BASE_SOCKET;
const SERVER_URL = `${socketUrl}`;

// Adding IDs to the types coming from backend
export type PortfolioWithId = PortfolioDto & { _id: string };

type ContextProps = {
  userLogin: UserLogin | null;
  userData: UserData | null;
  forceUpdate: boolean;
  setForceUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setUserData: React.Dispatch<React.SetStateAction<UserData | null>>;
  logInUser: (user: UserLogin) => void;
  logOutUser: () => void;
  socket: Socket | null;
  sendMessage: (payload: any) => void;
  getSocket: () => ReturnType<typeof io> | null;
  allPortfolios: PortfolioWithId[];
  setAllPortfolios: React.Dispatch<React.SetStateAction<PortfolioWithId[]>>;
  selectedPortfolio: string | any;
  setSelectedPortfolio: React.Dispatch<React.SetStateAction<string | any>>;
  fetchingUserData: () => void;
  notifications: Notification[];
  setNotifications: React.Dispatch<React.SetStateAction<Notification[]>>;
  notificationCount: number | null;
  setNotificationCount: React.Dispatch<React.SetStateAction<number | null>>;
  hasSubscription: boolean;
  subscriptionInfo: SubscriptionType;
  getSubscription: () => void;
};

export const AppContext = createContext<ContextProps | undefined>(undefined);

const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  // current user state
  const [userData, setUserData] = useState<UserData | null>(null);

  //all portfolios state
  const [allPortfolios, setAllPortfolios] = useState<PortfolioWithId[]>([]);

  // selected portfolio state
  const [selectedPortfolio, setSelectedPortfolio] = useState<string | null>(
    null
  );

  // userLogin state
  const [userLogin, setUserLogin] = useState<UserLogin | null>(null);

  const [forceUpdate, setForceUpdate] = useState<boolean>(false);

  // main loader for app load
  const [userLoading, setUserLoading] = useState(true);

  // Notifications state
  const [notifications, setNotifications] = useState<Notification[]>([]);

  // Socket
  const socket = useRef<ReturnType<typeof io> | null>(null);

  // Notification count
  const [notificationCount, setNotificationCount] = useState<number | null>(
    null
  );

  // check user in local storage on initial load
  useEffect(() => {
    const userLoginFromStorage = JSON.parse(localStorage.getItem("userLogin")!);
    setUserLogin(userLoginFromStorage);
    if (!userLoginFromStorage) {
      setUserLoading(false);
    }
  }, []);

  // store user in state and start socket
  useEffect(() => {
    // setup axios
    if (userLogin && socket.current === null) {
      setupAxios(userLogin?.token);
      connectSocket();
      fetchingUserData();
      fetchingAllPortfolios();
      getSubscription();
    }
  }, [userLogin]);

  useEffect(() => {
    fetchNotification();
  }, [socket.current]);

  function fetchNotification() {
    if (socket.current) {
      socket.current.on(SocketListeners.notifications, (data: any) => {
        if (data.COMMAND === SocketCommands.NOTIFICATIONS_COUNT) {
          setNotificationCount(
            Number(data?.unSeenNotifications) > 0
              ? Number(data?.unSeenNotifications)
              : null
          );
        }
        if (data?.length >= 0) {
          setNotifications(
            data?.map((notify: any) => {
              return {
                _id: notify?._id,
                title: notify?.title,
                description: notify?.description,
                seen: notify?.seen,
                createdAt: notify?.createdAt,
              };
            })
          );
        }
      });

      socket.current.emit(SocketEvents.NOTIFICATIONS, {
        COMMAND: SocketCommands.NOTIFICATIONS_COUNT,
      });
    }
  }

  const connectSocket = () => {
    const token = JSON.parse(localStorage.getItem("currentUserToken")!);

    const connection = io(`${SERVER_URL}/?token=${token}`, {
      transports: ["websocket"],
    });

    connection.on("join", (data) => {
      if (userData) {
        setUserLoading(false);
      }
      socket.current = connection;
      fetchNotification();
    });

    connection.on("disconnect", () => {
      console.log("Socket.io disconnected");
      socket.current = null;
      attemptReconnect();
    });

    connection.on("message", (event: any) => {
      const payload = event;

      switch (payload?.COMMAND) {
        case "JOIN":
          break;

        default:
          break;
      }
    });
  };

  const getSocket = () => {
    return socket.current;
  };

  const sendMessage = (payload: any) => {
    if (socket.current && socket.current.connected) {
      socket.current.emit("message", payload);
    } else {
      throw new Error("Socket not connected");
    }
  };

  const attemptReconnect = () => {
    const token = JSON.parse(localStorage.getItem("currentUserToken")!);
    if (token && !socket.current) {
      setTimeout(() => {
        console.log("Reconnecting...");
        connectSocket();
      }, 5000);
    }
  };

  async function fetchingUserData() {
    try {
      const response = await UserService.userControllerUser();
      if (response.success) {
        let data = response?.data;

        const userData: UserData = {
          _id: data?._id,
          country: data?.country,
          coverPicture: data?.coverPhoto,
          dateFormat: data?.dateFormat,
          email: data?.email,
          firstName: data?.firstName,
          lastName: data?.lastName,
          language: data?.language,
          phone: data?.phone,
          profilePicture: data?.profilePicture,
          role: data?.role,
          timezone: data?.timezone,
          passwordSet: data?.passwordSet,
          isDummyButtonHidden: data?.isDummyButtonHidden,
          tradeStorage: data?.tradeStorage,
        };

        setUserData(userData);
      } else {
        throw new Error("An error occurred while fetching user data");
      }
    } catch (error: any) {
      toast.remove();
      toast.error(
        error.body.message || "An error occurred while fetching user data"
      );
    }
    setUserLoading(false);
  }

  async function fetchingAllPortfolios() {
    try {
      const response =
        await PortfolioService.portfolioControllerGetPortfolios();

      if (response.success) {
        setAllPortfolios(response.data.allPortfolios);
      } else {
        throw new Error("An error occurred while fetching portfolios");
      }
    } catch (error: any) {
      toast.remove();
      toast.error(
        error.body.message || "An error occurred while fetching portfolios"
      );
    }
  }

  /**
   * To login user
   * @param data UserData
   */
  const logInUser = async (data: any) => {
     const loginData: UserLogin = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      userId: data?._id,
      email: data?.email,
      token: data?.token,
      role: data?.role,
      provider: data?.provider,
    };

    const userData: UserData = {
      _id: data?._id,
      country: data?.country,
      coverPicture: data?.coverPhoto,
      dateFormat: data?.dateFormat,
      email: data?.email,
      firstName: data?.firstName,
      lastName: data?.lastName,
      language: data?.language,
      phone: data?.phone,
      profilePicture: data?.profilePicture,
      role: data?.role,
      timezone: data?.timezone,
      passwordSet: data?.passwordSet,
      tradeStorage: data?.tradeStorage,
    };

    localStorage.setItem("userLogin", JSON.stringify(loginData));
    localStorage.setItem("currentUserToken", JSON.stringify(data.token));
    setUserLogin(loginData);
    setUserData(userData);
    // getSubscription();
  };

  const logOutUser = async () => {
    try {
      const response =
        await AuthenticationService.authControllerLogoutSession();
      if (response.success) {
        navigate("/login");
      } else {
        throw new Error("An error occurred while logging out");
      }
    } catch (error) {
      console.log(error);
      toast.remove();
      toast.error("An error occurred while logging out");
    }

    localStorage.removeItem("userLogin");
    localStorage.removeItem("currentUserToken");

    setUserData(null);
    setUserLogin(null);
    setNotifications([]);
    setNotificationCount(null);
    setSubscriptionInfo(InitialSubscriptionValues);
    socket.current?.disconnect();
    socket.current = null;
    setUserLoading(false);
  };

  /**
   * Subscription states
   */

  const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionType>(
    InitialSubscriptionValues
  );

  const getSubscription = async () => {
    try {
      const response =
        await SubscriptionsService.subscriptionControllerGetActiveSubscription();
      if (response.success) {
        const currentSubscription =
          response.data.subscription || InitialSubscriptionValues;
        setSubscriptionInfo(currentSubscription);
      } else {
        throw new Error("An error occurred while fetching subscription");
      }
    } catch (error: any) {
      toast.remove();
      toast.error(
        error.body.message || "An error occurred while fetching subscription"
      );
    }
  };

  let hasSubscription;
  if (subscriptionInfo.isActive) {
    hasSubscription = true;
  } else {
    hasSubscription = false;
  }

  return (
    <AppContext.Provider
      value={{
        userLogin,
        userData,
        setUserData,
        logInUser,
        logOutUser,
        forceUpdate,
        setForceUpdate,
        socket: socket.current,
        getSocket,
        sendMessage,

        allPortfolios,
        setAllPortfolios,

        selectedPortfolio,
        setSelectedPortfolio,

        fetchingUserData,

        notifications,
        setNotifications,

        notificationCount,
        setNotificationCount,

        hasSubscription,
        subscriptionInfo,
        getSubscription,
      }}
    >
      {userLoading ? (
        <div className="bg-white dark:bg-dark-background w-screen h-screen flex justify-center items-center">
          <TailSpin
            width={"54px"}
            height={"54px"}
            color={
              localStorage.getItem("theme") === "light" ? "#36454F" : "#3A6FF8"
            }
          />
        </div>
      ) : (
        children
      )}
    </AppContext.Provider>
  );
};

export default AppProvider;

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
