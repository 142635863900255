function SetupCells({ info }: { info: any }) {
  return (
    <>
      {info
        .getValue()
        .slice(0, 2)
        .map((ele: string) => {
          return (
            <div className=" py-1 rounded-[2px] px-2 flex justify-center items-center text-[8px] text-white dark:text-black bg-light-foreground dark:bg-dark-border">
              <p>{ele}</p>
            </div>
          );
        })}
    </>
  );
}

export default SetupCells;
