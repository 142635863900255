import { useState } from "react";
import Popup from "reactjs-popup";
import { FaTrashAlt } from "react-icons/fa";
import DeletePopupComponent from "./components";

//@ts-ignore
function DeleteButton({
  //   setAlert,
  row,
  handleDeleteButton,
}: {
  //   setAlert: (
  //     state: {
  //       message: string;
  //       type: "info" | "danger" | "success" | "warning" | "dark" | null;
  //     } | null
  //   ) => void;
  row: any;
  handleDeleteButton: (id: any) => void;
}) {
  // Delete popup state
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  return (
    <td
      style={{ width: "50px" }}
      className="flex justify-center items-center ml-auto"
    >
      <Popup
        open={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        modal
        closeOnEscape={false}
        closeOnDocumentClick={false}
      >
        <DeletePopupComponent
          row={row}
          handleDeleteButton={handleDeleteButton}
          onClose={() => setIsDeleteOpen(false)}
        />
      </Popup>
      <button
        className="p-1 border border-black dark:border-dark-border text-black dark:text-dark-border rounded cursor-pointer hover:opacity-80 transition-opacity"
        onClick={() => setIsDeleteOpen(true)}
      >
        <FaTrashAlt size={15} />
      </button>
    </td>
  );
}

export default DeleteButton;
