/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeletePortfolioDto } from '../models/DeletePortfolioDto';
import type { PortfolioDto } from '../models/PortfolioDto';
import type { UpdatePortfolioDto } from '../models/UpdatePortfolioDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PortfolioService {
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static portfolioControllerAddPortfolio(
        requestBody: PortfolioDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/api/portfolio',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static portfolioControllerDeletePortfolio(
        requestBody: DeletePortfolioDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/api/portfolio',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param portfolioId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static portfolioControllerUpdatePortfolio(
        portfolioId: string,
        requestBody: UpdatePortfolioDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/api/portfolio',
            query: {
                'portfolioId': portfolioId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    public static portfolioControllerGetPortfolios(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/api/portfolio',
        });
    }
}
