import AsyncButton from "../../../../../../../../../../components/AsyncButton";

function DeletePopupComponent({
  row,
  handleDeleteButton,
  onClose,
}: {
  row: any;
  handleDeleteButton: (id: string) => void;
  onClose: () => void;
}) {
  return (
    <div className="relative bg-grayMain dark:bg-dark-background w-full lg:p-8 p-5 rounded-lg">
      <div className="w-full absolute bg-light-foreground text-white dark:bg-dark-border top-0 right-0 left-0 p-2">
        <h1>Delete trade</h1>
      </div>
      <div className="flex justify-start items-start flex-col gap-3">
        <div className="mt-8">
          <p className="text-base text-black dark:text-white">
            Are you sure you want to delete this set of trades? All the
            associated data such but not limited to setups, notes, screenshots,
            and journal entries will be lost and non-recoverable.
          </p>
        </div>
        <div className="flex w-full justify-between items-center mt-3">
          <button
            onClick={onClose}
            type="button"
            className="bg-light-lightGray dark:bg-[#66b2b244] text-light-foreground dark:text-dark-border py-2 px-5 rounded hover:opacity-80 transition-opacity"
          >
            Cancel
          </button>
          <AsyncButton
            type="submit"
            buttonClass="bg-light-foreground text-white dark:bg-dark-border hover:opacity-80 transition-opacity py-2 px-5 rounded-md cursor-pointer"
            onClick={() => {
              handleDeleteButton(row.original._id);
              onClose();
            }}
          >
            Yes
          </AsyncButton>
        </div>
      </div>
    </div>
  );
}
export default DeletePopupComponent;
