import DashboardIcon from "./icons/NavIcons/Dashboard.svg?react";
import PerformanceIcon from "./icons/NavIcons/Performance.svg?react";
import PortfoliosIcon from "./icons/NavIcons/Portfolios.svg?react";
import ReportsIcon from "./icons/NavIcons/Reports.svg?react";
import JournalIcon from "./icons/NavIcons/Journal.svg?react";
import TradeIcon from "./icons/NavIcons/Trade.svg?react";
import PreTradeIcon from "./icons/Pre trading Question.svg?react";
import PostTradeIcon from "./icons/Post Trading Question.svg?react";
import HelpIcon from "./icons/UserDropdownIcons/help.svg?react";
import LightModeIcon from "./icons/UserDropdownIcons/light-mode.svg?react";
import ProfileSettingsIcon from "./icons/UserDropdownIcons/settings.svg?react";
import SignOutIcon from "./icons/UserDropdownIcons/sign-out.svg?react";
import UpgradePlanIcon from "./icons/UserDropdownIcons/upgrade.svg?react";
import Plus from "./tradeSummary/plus.svg?react";
import Minus from "./tradeSummary/minus.svg?react";
import Edit from "./tradeSummary/edit.svg?react";
import Calendar from "./icons/Calendar icon.svg?react";
import BellIcon from "./icons/Bell.svg?react";
import PDF from "./icons/PDF.svg?react";
import DOTS from "./icons/Dots.svg?react";
import VISA from "./icons/visa.svg?react";
import LockIcon from "./icons/Lock.svg?react";
import Lock2Icon from "./icons/Lock2Icon.svg?react";

import StatusIcon from "./icons/Status_list.svg?react";
import CandleStickIcon from "./icons/Candlestick.svg?react";
// import
import RightArrow from "./import/right_arrow.svg?react";
import FileDoc from "./import/File_dock.svg?react";
import Cross from "./import/circle-cross.svg?react";

// dashboard

import UndoArrow from "./dashboard/arrow-undo-up-right.svg?react";
import Filters from "./dashboard/filter-svgrepo-com.svg?react";
import DownArrow from "./dashboard/down-arrow-svgrepo-com.svg?react";
import InfoIcon from "./dashboard/i_mark.svg?react";
import Setting from "./dashboard/setting.svg?react";

import Membership from './icons/membership.svg?react'
import EyeIcon from './icons/eye-icon.svg?react'
import CreditCards from './icons/creditCards.svg?react'
import ViewDetails from './icons/viewdetailsicon.svg?react'
import PencilIcon from './icons/PencilIcon.svg?react'
import DualArrows from './icons/ArrowsIcon.svg?react'
import CreditCard from './icons/creaditCard.svg?react'

export const images = {
  DashboardIcon: (props: any) => <DashboardIcon className={`${props}`} />,
  PerformanceIcon: (props: any) => <PerformanceIcon className={`${props}`} />,
  JournalIcon: (props: any) => <JournalIcon className={`${props}`} />,
  Calendar: (props: any) => <Calendar className={`${props}`} />,
  PortfoliosIcon: (props: any) => <PortfoliosIcon className={`${props}`} />,
  ReportsIcon: (props: any) => <ReportsIcon className={`${props}`} />,
  PreTradeIcon: (props: any) => <PreTradeIcon className={`${props}`} />,
  PostTradeIcon: (props: any) => <PostTradeIcon className={`${props}`} />,
  TradeIcon: (props: any) => <TradeIcon className={`${props}`} />,
  HelpIcon: (props: any) => <HelpIcon className={`${props}`} />,
  LightModeIcon: (props: any) => <LightModeIcon className={`${props}`} />,
  ProfileSettingsIcon: (props: any) => (
    <ProfileSettingsIcon className={`${props}`} />
  ),
  SignOutIcon: (props: any) => <SignOutIcon className={`${props}`} />,
  UpgradePlanIcon: (props: any) => <UpgradePlanIcon className={`${props}`} />,
  BellIcon: (props: any) => <BellIcon className={`${props}`} />,
  PDF: (props: any) => <PDF className={`${props}`} />,
  DOTS: (props: any) => <DOTS className={`${props}`} />,
  VISA: (props: any) => <VISA className={`${props}`} />,
  LockIcon: (props: any) => <LockIcon className={`${props}`} />,
  Lock2Icon: (props: any) => <Lock2Icon className={`${props}`} />,
  StatusIcon: (props: any) => <StatusIcon className={`${props}`} />,
  CandleStickIcon: (props: any) => <CandleStickIcon className={`${props}`} />,
  // import
  RightArrow: (props: any) => <RightArrow className={`${props}`} />,
  FileDoc: (props: any) => <FileDoc className={`${props}`} />,
  Cross: (props: any) => <Cross className={`${props}`} />,

  //
  Plus: (props: any) => <Plus className={`${props}`} />,
  Minus: (props: any) => <Minus className={`${props}`} />,
  Edit: (props: any) => <Edit className={`${props}`} />,

  // dashboard
  UndoArrow: (props: any) => <UndoArrow className={`${props}`} />,
  Filters: (props: any) => <Filters className={`${props}`} />,
  DownArrow: (props: any) => <DownArrow className={`${props}`} />,
  InfoIcon: (props: any) => <InfoIcon className={`${props}`} />,
  Setting: (props: any) => <Setting className={`${props}`} />,

  //Membership
  Membership: (props: any) => <Membership className={`${props}`} />,
  EyeIcon: (props: any) => <EyeIcon className={`${props}`} />,
  CreditCards: (props: any) => <CreditCards className={`${props}`} />,
  ViewDetails: (props: any) => <ViewDetails className={`${props}`} />,
  PencilIcon: (props: any) => <PencilIcon className={`${props}`} />,
  DualArrows: (props: any) => <DualArrows className={`${props}`} />,
  CreditCard: (props: any) => <CreditCard className={`${props}`} />,
};
