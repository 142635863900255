import { memo, useState } from "react";
import { images } from "../../../../assets/images";
import { motion, AnimatePresence } from "framer-motion";
import AsyncButton from "../../../../components/AsyncButton";
import { TailSpin } from "react-loader-spinner";
import { TbArrowForwardUp } from "react-icons/tb";
import FilterPopup from "../../trades/tradeslog/components/TradesLogTable/Table/components/filterpopup";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { IoIosClose } from "react-icons/io";
import { useTheme } from "../../../../context/ThemeContext";
import {
  FilterOptions,
  SelectedFilterOption,
} from "../../trades/tradeslog/components/TradesLogTable/TradesLogTable";

const Menu = ({
  setToggleCalendar,
  toggleCalendar,
  filtersOptions,
  allSelectedFilters,
  handleTagRemove,
  isFilterSelected,
  setIsFilterSelected,
  setAllSelectedFilters,
}: {
  setToggleCalendar: (value: boolean) => void;
  toggleCalendar: boolean;
  filtersOptions: FilterOptions[];
  allSelectedFilters: SelectedFilterOption[];
  handleTagRemove: (value: string) => void;
  isFilterSelected: boolean;
  setIsFilterSelected: (value: boolean) => void;
  setAllSelectedFilters: any;
}) => {
  const { theme } = useTheme();

  const [selectedDate, setSelectedDate] = useState<Date>();
  const [isCalendarSwitched, setIsCalendarSwitched] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const dateButtonVariants = {
    initial: { x: 0, opacity: 1 },
    animate: {
      x: isCalendarSwitched ? -150 : 0,
      opacity: isCalendarSwitched ? 0 : 1,
      transition: { duration: 0.5 },
    },
    exit: { x: 0, opacity: 1 },
  };
  const calendarButtonVariants = {
    initial: { x: 0 },
    animate: { x: isCalendarSwitched ? -150 : 0 },
    fastMove: { x: isCalendarSwitched ? -150 : 0 },
    exit: { x: 0 },
  };

  const handleCheckboxChange = (option: SelectedFilterOption) => {
    let updatedSelectedItems: SelectedFilterOption[];

    // Check if the selected option is for 'date'
    if (option.name === "date") {
      // If it's a date, reset the selection to just the current option
      updatedSelectedItems = [option];
    } else {
      // For other options, toggle the checkbox
      if (!!allSelectedFilters.find((item) => item.value === option.value)) {
        updatedSelectedItems = allSelectedFilters.filter(
          (item) => item.value !== option.value
        );
      } else {
        updatedSelectedItems = [...allSelectedFilters, option];
      }
    }

    setAllSelectedFilters(updatedSelectedItems);
  };

  return (
    <div className="pt-10 pb-2 flex flex-col items-center justify-between">
      <div className="w-full flex items-center justify-between">
        <div className="flex gap-5 relative">
          {/* Date Display and Calendar */}
          <motion.button
            className="bg-white  flex items-center gap-3 text-lg py-2 px-4 rounded-lg dark:text-dark-border dark:bg-dark-background"
            variants={dateButtonVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.5 }}
            onClick={() => setIsCalendarOpen(!isCalendarOpen)}
          >
            <span>{images.PencilIcon("")}</span>
            <span>
              {selectedDate
                ? moment(selectedDate).format("MMMM, YYYY")
                : "To Date"}
            </span>
          </motion.button>
          {isCalendarOpen && !isCalendarSwitched && (
            <div className="absolute top-12">
              <DatePicker
                selected={selectedDate}
                onChange={(date: any) => {
                  setSelectedDate(date);
                  setIsCalendarOpen(false);
                  handleCheckboxChange({
                    name: "date",
                    value: moment(date).format("MMMM, YYYY"),
                  });
                }}
                inline
                showMonthYearPicker
                calendarClassName="!bg-[#E9E8E8] dark:!bg-dark-backgroundMain dark:!text-dark-border !z-[100]"
              />
            </div>
          )}
          <motion.div
            className="bg-white flex items-center gap-3 text-lg py-2 px-4 rounded-lg dark:text-dark-border dark:bg-dark-background cursor-pointer"
            onClick={() => [
              setIsCalendarSwitched(!isCalendarSwitched),
              setToggleCalendar(!toggleCalendar),
            ]}
            variants={calendarButtonVariants}
            initial="initial"
            animate={isCalendarSwitched ? "fastMove" : "animate"}
            exit="exit"
            transition={{
              x: { duration: 0.4, ease: "easeOut" },
              opacity: { duration: 0.3 },
            }}
          >
            <span>{images.DualArrows("")}</span>
            <span>Switch Calendar</span>
          </motion.div>
        </div>
        {isCalendarSwitched && (
          <div
            onClick={() => setIsCalendarOpen(!isCalendarOpen)}
            className="relative ml-auto mr-4"
          >
            {images.Calendar(
              `w-7 h-7 cursor-pointer ${
                theme !== "light"
                  ? "stroke-dark-border fill-transparent"
                  : "!stroke-light-foreground fill-transparent"
              }`
            )}
            {isCalendarOpen && (
              <div className="absolute w-[19rem] -left-20  top-12">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date: any) => {
                    setSelectedDate(date);
                    setIsCalendarOpen(false);
                    handleCheckboxChange({
                      name: "date",
                      value: moment(date).format("MMMM, YYYY"),
                    });
                  }}
                  inline
                  showMonthYearPicker
                  calendarClassName="!bg-[#E9E8E8] dark:!bg-dark-backgroundMain dark:!text-dark-border !z-[100]"
                />
              </div>
            )}
          </div>
        )}
        <div className="relative  ">
          <AnimatePresence mode="wait">
            {!isFilterSelected ? (
              <>
                <button
                  onClick={() => setIsFilterSelected(true)}
                  className="flex items-center gap-2 text-md py-2 px-6 rounded-lg bg-light-foreground text-white dark:text-dark-background dark:bg-dark-border"
                >
                  Filters
                </button>
              </>
            ) : (
              <>
                {filtersOptions.length > 0 ? (
                  <motion.div
                    className="flex justify-end items-center flex-wrap gap-2 relative"
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 50, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {filtersOptions.map((fil: any) => (
                      <FilterPopup
                        key={fil.name}
                        filterName={fil.name}
                        options={fil.options}
                        allSelectedFilters={allSelectedFilters}
                        setAllSelectedFilters={setAllSelectedFilters}
                        searchable={
                          fil.name.toLowerCase() === "symbol" ? true : false
                        }
                      />
                    ))}
                    <button
                      onClick={() => setIsFilterSelected(false)}
                      className="bg-light-foreground text-white dark:bg-white dark:text-black hover:opacity-80 transition-opacity p-2 rounded-md cursor-pointer"
                    >
                      <TbArrowForwardUp
                        size={20}
                        className="text-white dark:text-black"
                      />
                    </button>
                  </motion.div>
                ) : (
                  <div className="flex items-center justify-between py-3 min-w-[50px]">
                    <TailSpin
                      width={"28px"}
                      height={"28px"}
                      color={theme === "light" ? "#36454F" : "#3A6FF8"}
                    />
                  </div>
                )}
              </>
            )}
          </AnimatePresence>
        </div>
      </div>
      <div className="mt-2 flex flex-wrap justify-start w-full relative">
        {allSelectedFilters.map((selectedFilter: any) => (
          <div
            key={selectedFilter.value}
            className="justify-center gap-1 flex items-center dark:bg-dark-border bg-white text-gray-700 text-xs font-medium mr-2 mb-2 px-2 py-1 rounded"
          >
            <div className="text-xs">{selectedFilter.value}</div>
            <button
              type="button"
              onClick={() => handleTagRemove(selectedFilter.value)}
              className="ml-1 bg-transparent text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <IoIosClose size={18} />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Menu;
