/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTradeDTO } from '../models/CreateTradeDTO';
import type { DeleteScreenShotDTO } from '../models/DeleteScreenShotDTO';
import type { ReAssignTradesDTO } from '../models/ReAssignTradesDTO';
import type { TradeFilterAndQueries } from '../models/TradeFilterAndQueries';
import type { UpdateScreenShotsDTO } from '../models/UpdateScreenShotsDTO';
import type { UpdateTradeDTO } from '../models/UpdateTradeDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TradesService {
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static tradesControllerCreateTrade(
        requestBody: CreateTradeDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/api/trades',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    public static tradesControllerDeleteTrades(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/api/trades',
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static tradesControllerCreateBulkTrades(
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/api/trades/bulk',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static tradesControllerFindTrades(
        requestBody: TradeFilterAndQueries,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/api/trades/find',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param type
     * @returns any
     * @throws ApiError
     */
    public static tradesControllerGetTradeFilters(
        type: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/api/trades/filters',
            query: {
                'type': type,
            },
        });
    }
    /**
     * @param type
     * @returns any
     * @throws ApiError
     */
    public static tradesControllerGetTradeStats(
        type: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/api/trades/stats',
            query: {
                'type': type,
            },
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    public static tradesControllerGetSetupStats(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/api/trades/setup-stats',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    public static tradesControllerAccountPerformance(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/api/trades/performance',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    public static tradesControllerPotentialPerformance(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/api/trades/potential-performance',
        });
    }
    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static tradesControllerFindOne(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/api/trades/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param childTradeId
     * @returns any
     * @throws ApiError
     */
    public static tradesControllerRemoveTrade(
        childTradeId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/api/trades/delete/{childTradeId}',
            path: {
                'childTradeId': childTradeId,
            },
        });
    }
    /**
     * @param portfolioId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static tradesControllerReAssignTrade(
        portfolioId: string,
        requestBody: ReAssignTradesDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/api/trades/re-assign/{portfolioId}',
            path: {
                'portfolioId': portfolioId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param tradeId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static tradesControllerUpdateTrade(
        tradeId: string,
        requestBody: UpdateTradeDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/api/trades/update/{tradeId}',
            path: {
                'tradeId': tradeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param tradeId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static tradesControllerUpdateScreenShots(
        tradeId: string,
        requestBody: UpdateScreenShotsDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/api/trades/screenshots/{tradeId}',
            path: {
                'tradeId': tradeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param tradeId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static tradesControllerDeleteScreenShot(
        tradeId: string,
        requestBody: DeleteScreenShotDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/api/trades/screenshots/{tradeId}',
            path: {
                'tradeId': tradeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
