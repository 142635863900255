import React, { useState } from "react";
import DeleteTrades from "../../../../../../portfolios/components/Table/components/DeleteTrade/DeleteTrades";
import ReassignTrades from "../../../../../../portfolios/components/Table/components/DeletePortfolio/components";
import { TradesService } from "../../../../../../../../../generated/services/TradesService";
import toast from "react-hot-toast";
import Popup from "reactjs-popup";

type ActionsProps = {
  selectedRows: string[];
  fetchTradesData: () => void;
  setIsOpen: () => void;
  setSelectedRows: () => void;
};

type ActionItem = {
  accessorKey: string;
  header: string;
};

const actions: ActionItem[] = [
  {
    accessorKey: "delete",
    header: "Delete Trades",
  },
  {
    accessorKey: "reAssign",
    header: "Assign Portfolio",
  },
];

export default function Actions({
  selectedRows,
  fetchTradesData,
  setIsOpen,
  setSelectedRows,
}: ActionsProps) {
  const [selectAccessor, setSelectAccessor] = useState<string>("");
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [isReassignOpen, setIsReassignOpen] = useState<boolean>(false);
  const [isDeleteTradesLoading, setIsDeleteTradesLoading] = useState(false);

  const handleAction = (accessorKey: string) => {
    if (accessorKey === "delete") {
      setIsDeleteOpen(true);
      setSelectAccessor(accessorKey);
    } else {
      setIsReassignOpen(true);
      setSelectAccessor(accessorKey);
    }
  };

  async function tradesControllerReAssignTrade(reassignPortfolioId?: string) {
    setIsDeleteTradesLoading(true);
    try {
      if (!selectedRows || selectedRows.length === 0) {
        throw new Error("No trades selected for reassignment.");
      }

      const requestBody = {
        tradeIds: selectedRows,
      };

      const response = await TradesService.tradesControllerReAssignTrade(
        reassignPortfolioId as string,
        requestBody
      );

      if (response && response.message) {
        toast.success(response.message || "Trades reassigned successfully");
        setIsOpen();
        setSelectedRows();
      } else {
        setIsOpen();
        setSelectedRows();
        throw new Error("An error occurred while reassigning trades");
      }
    } catch (error: any) {
      toast.error(error.message || "Failed to reassign trades");
    } finally {
      setSelectAccessor("");
      setIsDeleteTradesLoading(false);
      setIsReassignOpen(false);
    }
  }

  return (
    <>
      <div className="bg-light-foreground dark:bg-grayMain max-h-[200px] overflow-auto dark:text-dark-background text-white px-3 w-[200px] flex flex-col gap-2 shadow-md rounded">
        {actions.map((column: ActionItem, idx: number) => (
          <div
            key={column.accessorKey}
            className={`flex justify-start items-center py-1 text-sm ${
              idx !== actions.length - 1 &&
              "border-b border-grayMain dark:border-light-border"
            }`}
            onClick={() => {
              handleAction(column.accessorKey);
            }}
          >
            <label className="cursor-pointer flex justify-center items-center">
              <span className="ml-2">{column.header}</span>
            </label>
          </div>
        ))}
      </div>

      <Popup
        open={isDeleteOpen}
        onClose={() => {
          setIsDeleteOpen(false);
          setIsOpen();
        }}
        modal
        closeOnDocumentClick
        closeOnEscape={false}
      >
        <div
          className="relative bg-grayMain dark:bg-dark-background w-full rounded-lg"
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
        >
          <DeleteTrades
            onClose={() => setIsDeleteOpen(false)}
            setIsOpen={setIsOpen}
            trades={selectedRows}
            setSelectedRows={setSelectedRows}
            fetchTradesData={fetchTradesData}
          />
        </div>
      </Popup>

      <Popup
        open={isReassignOpen}
        onClose={() => {
          setIsReassignOpen(false);
          setIsOpen();
        }}
        modal
        closeOnDocumentClick
        closeOnEscape={false}
      >
        <div
          className="relative bg-grayMain dark:bg-dark-background w-full rounded-lg"
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
        >
          <div className="w-full bg-light-foreground rounded-t-lg  text-white dark:bg-dark-border p-2">
            <h1>Reassign your trades</h1>
          </div>
          <div className="mt-8 space-y-5 px-6">
            <ReassignTrades
              onClose={() => {
                setSelectAccessor("");
                setIsReassignOpen(false);
              }}
              isDeleteTradesLoading={isDeleteTradesLoading}
              deletePortfolioPlusTrades={tradesControllerReAssignTrade}
            />
          </div>
        </div>
      </Popup>
    </>
  );
}
