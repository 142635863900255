import React, { useState, useEffect } from "react";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import { TbArrowBackUp } from "react-icons/tb";
import AsyncButton from "../../../../components/AsyncButton";
import { JournalService } from "../../../../../generated/services/JournalService";
import toast from "react-hot-toast";

const emojis = ["😖", "😟", "😐", "🙂", "😊"];
const labels = ["Very Unhappy", "Unhappy", "Neutral", "Happy", "Very Happy"];

export default function PreTradingQuestion({
  questions,
  closeModal,
  journal,
  title,
  journalData,
  setJournalData,
}: {
  questions: string[];
  closeModal: (value: boolean) => void;
  journal: any;
  title: string;
  journalData: any;
  setJournalData: (item: any) => void;
}) {
  // Initialize the ratings with 0 or pre-existing values from journalData
  const [ratings, setRatings] = useState<number[]>(
    new Array(questions.length).fill(0)
  );

  useEffect(() => {
    // Map the preTradingQuestions into the ratings array based on the question index
    if (journalData?.preTradingQuestions) {
      const preExistingRatings = questions.map((question) => {
        // Find the matching question in preTradingQuestions
        const found = journalData.preTradingQuestions.find((q: string) =>
          q.startsWith(question)
        );
        // Extract the rating from the found string (e.g., "How well-rested do you feel today?: 2")
        return found ? parseInt(found.split(": ")[1]) : 0;
      });
      setRatings(preExistingRatings);
    }
    if (journalData?.postTradingQuestions) {
      const preExistingRatings = questions.map((question) => {
        // Find the matching question in postTradingQuestions
        const found = journalData.postTradingQuestions.find((q: string) =>
          q.startsWith(question)
        );
        // Extract the rating from the found string (e.g., "How well-rested do you feel today?: 2")
        return found ? parseInt(found.split(": ")[1]) : 0;
      });
      setRatings(preExistingRatings);
    }
  }, [journalData, questions]);

  const handleRatingChange = (questionIndex: number, rating: number) => {
    const newRatings = [...ratings];
    newRatings[questionIndex] =
      newRatings[questionIndex] === rating ? 0 : rating;
    setRatings(newRatings);
  };

  const handleSubmit = async () => {
    // Convert the questions and ratings into string format for DTO compatibility
    const output = questions.map(
      (question, index) => `${question}: ${ratings[index]}`
    );

    let payload;
    if (title === "Pre-Trading Questions") {
      payload = {
        preTradingQuestions: output,
      };
    } else {
      payload = {
        postTradingQuestions: output,
      };
    }

    try {
      if (!payload) {
        throw new Error("Payload is not constructed correctly.");
      }

      // Check if it's an update or create operation
      if (journalData?._id) {
        const response =
          await JournalService.tradesJournalControllerUpdateJournal(
            journalData?._id,
            payload
          );
        if (!response || !response.success) {
          toast.error("Journal Updation failed, no response received.");
        }

        if (response.success) {
          setJournalData(response.data);
          closeModal(false);
        }
      } else {
        const response =
          await JournalService.tradesJournalControllerCreateJournal({
            date: journal.date,
            ...payload,
          });

        if (!response || !response.success) {
          toast.error("Journal creation failed, no response received.");
        }

        if (response.success) {
          setJournalData(response.data);
          closeModal(false);
        }
      }
    } catch (err) {
      console.error("Error saving journal:", err);
      toast.error(
        "There was an issue saving the journal. Please try again later."
      );
    }
  };

  return (
    <div className="font-poppins p-6 bg-[#E9E8E8] dark:bg-dark-backgroundMain">
      <div className="flex flex-col gap-4 mb-3">
        <button
          onClick={() => closeModal(false)}
          className="dark:bg-white text-white w-max bg-white dark:text-black hover:opacity-80 transition-opacity p-2 rounded-md cursor-pointer"
        >
          <TbArrowBackUp size={20} className=" text-black" />
        </button>
        <h1 className="text-[22px] dark:text-white font-medium">{title}</h1>
      </div>

      {questions.map((question, questionIndex) => (
        <div key={questionIndex} className="mb-6">
          <h2 className="text-lg tracking-wider text-dark-background dark:text-white font-normal mb-2">
            {question}
          </h2>
          <div className="flex justify-start gap-16 items-start">
            {[1, 2, 3, 4, 5].map((rating) => (
              <div key={rating} className="flex items-center gap-4">
                <Checkbox
                  checked={ratings[questionIndex] === rating}
                  onChange={() => handleRatingChange(questionIndex, rating)}
                />
                <div className="flex flex-col items-center">
                  <span className="text-2xl mt-1">{emojis[rating - 1]}</span>
                  <span className="font-light text-xs dark:text-white mt-1 text-center">
                    {labels[rating - 1]} 
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}

      <div className="flex justify-start gap-6 items-center">
        <button
          onClick={() => closeModal(false)}
          type="button"
          className="rounded-md dark:bg-[#66b2b244] text-light-foreground border-[1px] border-light-foreground dark:text-dark-border py-2 px-5 hover:opacity-80 transition-opacity"
        >
          Cancel
        </button>
        <AsyncButton
          onClick={handleSubmit}
          type="submit"
          buttonClass="bg-light-foreground text-white dark:bg-dark-border hover:opacity-80 transition-opacity py-2 px-5 rounded-md cursor-pointer"
        >
          Submit
        </AsyncButton>
      </div>
    </div>
  );
}
