import { ColumnDef } from "@tanstack/react-table";
import React, { memo, useEffect, useState } from "react";
import Table from "./Table";
import StatusCell from "./cell-components/status";
import DateCell from "./cell-components/dateCell";
import {
  convertToTimezone,
  formatDate,
  generateApiPayloadFilters,
  generateRowsPerPageOptions,
} from "../../../../../../utils/utils";
import SetupCells from "./cell-components/setupsCell";
import DropdownHeader from "./header-components/dropdownComponent";
import toast from "react-hot-toast";
import { TradesService } from "../../../../../../../generated/services/TradesService";
import { TailSpin } from "react-loader-spinner";
import { useTheme } from "../../../../../../context/ThemeContext";
import { useAppContext } from "../../../../../../context/AppContext";
import { FaPlus } from "react-icons/fa6";
import PaginationComponent from "./Table/components/paginationComponent/PaginationComponent";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaFilter } from "react-icons/fa";
import { TbArrowForwardUp } from "react-icons/tb";
import { motion } from "framer-motion";
import FilterPopup from "./Table/components/filterpopup";
import EditColumns from "./components/editColumns";
import FlyoutComponent from "../../../../../../components/FlyoutComponent";
import { IoIosClose } from "react-icons/io";
import Checkbox from "../../../../../../components/Checkbox/Checkbox";
import Actions from "./components/actions/Actions";
import axios from "axios";

const MemoizedEditColumns = memo(EditColumns);
const MemoizedActions = memo(Actions);
const MemoizedFlyoutComponent = memo(FlyoutComponent);

// Component types
export type Option = {
  value: string;
  label: string;
};

export type FilterOptions = {
  name: string;
  options: Option[];
};

export type SelectedFilterOption = {
  name: string;
  value: string;
};

// const allFilters: SymbolOptions[] = [
//   {
//     name: "Symbol",
//     options: [
//       {
//         value: "BUY THE DIP",
//         label: "BUY THE DIP",
//       },
//       {
//         value: "DAY BREAKOUT",
//         label: "DAY BREAKOUT",
//       },
//     ],
//   },
//   { name: "size", options: [] },
//   { name: "Avg. Price", options: [] },
//   { name: "Price Target", options: [] },
//   { name: "Profit Target", options: [] },
//   { name: "Risk", options: [] },
//   { name: "Return", options: [] },
//   { name: "Side", options: [] },
//   { name: "Setup", options: [] },
// ];

export type TradeType = {
  _id: string;
  status: "open" | "win" | "loss";
  openDate: Date | string;
  closeDate: Date | string;
  symbol: string;
  size: number;
  price: number;
  currentPrice: number;
  priceTarget: number;
  profitTarget: number;
  risk: number;
  exitPrice: number;
  returnPrice: number;
  side: "buy" | "sell";
  setups: any[];
  market?: string;
  position?: number;
  value?: number;
  returns?: number;
  commission?: number;
  fees?: number;
  returnPercentage?: number;
  entryDate?: Date | string;
  portfolioId?: any;
  netReturns?: number;
  expire?: Date | string;
  strike?: number;
  spread?: string;
  screenshots?: string[];
  createdAt?: Date | string;
  isDummy?: boolean;
  contract_multiplier?: number;
  type?: string;
};

function TradesLogTable({
  props,
  hideFilter = false,
}: {
  props?: any;
  hideFilter?: boolean;
}) {
  const { theme } = useTheme();
  const { symbol } = useParams();
  const navigate = useNavigate();
  // Trades states for fetching loading and saving in state
  const [trades, setTrades] = useState<TradeType[] | null>(null);
  const [isLoadingTrades, setIsLoadingTrades] = useState<boolean>(false);

  // state for showing $ and % according to the condition
  const [profitTargetUnit, setProfitTargetUnit] = useState("%");
  const [riskUnit, setRiskUnit] = useState("%");

  // selecting units between $ and % function
  const handleProfitTargetSelect = (value: string) =>
    setProfitTargetUnit(value);
  const handleRiskSelect = (value: string) => setRiskUnit(value);

  // Initialize the column visibility state
  const [columnVisibility, setColumnVisibility] = useState<{
    [key: string]: boolean;
  }>({
    select: props?.trades || props?.reports ? false : true,
    status: true,
    openDate: true,
    closeDate: true,
    symbol: true,
    size: true,
    price: true,
    currentPrice: true,
    priceTarget: true,
    profitTarget: props?.trades || props?.reports ? false : true,
    risk: props?.trades || props?.reports ? false : true,
    exitPrice: props?.trades || props?.reports ? false : true,
    returnPrice: true,
    side: true,
    setups: true,
  });

  // Filter selected state
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  // All selected filters array
  const [filtersOptions, setFiltersOptions] = useState<FilterOptions[]>([]);
  const [allSelectedFilters, setAllSelectedFilters] = useState<
    SelectedFilterOption[]
  >([]);
  useEffect(() => {
    if (symbol) {
      setIsFilterSelected(true);
      setAllSelectedFilters([{ name: "Symbol", value: symbol }]);
    }
  }, [symbol]);
  const [isOpen, setIsOpen] = useState(false);
  const [rowSelection, setRowSelection] = useState({});

  // Function to toggle the div open/close
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  //Remove filter function from outside
  const handleTagRemove = (itemValue: string) => {
    if (symbol) {
      navigate("/trades");
      setIsFilterSelected(false);
      setAllSelectedFilters([]);
    }
    const updatedSelectedItems = allSelectedFilters.filter(
      (selectedItem) => selectedItem.value !== itemValue
    );
    setAllSelectedFilters(updatedSelectedItems);

    // Example: Make an API call when an item is removed
    // axios
    //   .post(apiEndpoint, { selectedItems: updatedSelectedItems })
    //   .catch((err) => console.error(err));
  };

  // Pagination state and functions
  const [paginationState, setPaginationState] = useState(() => {
    // Load pagination state from local storage if available
    const savedState = localStorage.getItem("paginationState");
    return savedState
      ? JSON.parse(savedState)
      : { totalRows: 0, rowsPerPage: 10, currentPage: 1 };
  });

  useEffect(() => {
    // Save pagination state to local storage whenever it changes
    localStorage.setItem("paginationState", JSON.stringify(paginationState));
  }, [paginationState]);

  const [paginationLoader, setPaginationLoader] = useState<boolean>(false);

  const handlePageChange = (page: number) => {
    fetchTradesData({}, { page: page, limit: paginationState.rowsPerPage });
  };

  const handleRowsPerPageChange = (rowsPerPage: number) => {
    fetchTradesData({}, { limit: rowsPerPage });
  };

  useEffect(() => {
    if (props?.trades) {
      setTrades(props?.trades);
    } else {
      fetchTradesData();
    }
    getTradesFilter();
  }, [props?.filter, props?.trades]);

  useEffect(() => {
    if (allSelectedFilters) {
      if (props?.trades) {
        setTrades(props?.trades);
      } else {
        fetchTradesData(generateApiPayloadFilters(allSelectedFilters), {});
      }
    }
  }, [allSelectedFilters]);

  async function getTradesFilter() {
    try {
      const response = await TradesService.tradesControllerGetTradeFilters(
        "all"
      );
      if (response.success) {
        const mapApiResponseToOptions = (data: any): FilterOptions[] => {
          return Object.entries(data).map(([key, values]) => ({
            name: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize first letter
            //@ts-ignore
            options: values.map((value: string | number) => ({
              value,
              label: value,
            })),
          }));
        };

        setFiltersOptions(mapApiResponseToOptions(response.data));

        // overwriting the coming filters from backend
        setFiltersOptions((prev) => {
          return prev.map((filterOption) => {
            if (filterOption.name === "Side") {
              return {
                name: "Side",
                options: [
                  { value: "buy", label: "Buy" },
                  { value: "sell", label: "Sell" },
                ],
              };
            } else if (filterOption.name === "RiskPercentage") {
              return {
                name: "Risk",
                options: [
                  { value: "0 - 10", label: "0 - 10" },
                  { value: "10 - 20", label: "10 - 20" },
                  { value: "20 - 30", label: "20 - 30" },
                  { value: "30 - 40", label: "30 - 40" },
                  { value: "40 - 50", label: "40 - 50" },
                  { value: "50 - 60", label: "50 - 60" },
                  { value: "60 - 70", label: "60 - 70" },
                  { value: "70 - 80", label: "70 - 80" },
                  { value: "80 - 90", label: "80 - 90" },
                  { value: "90 - 100", label: "90 - 100" },
                ],
              };
            } else if (filterOption.name === "PriceTarget") {
              return {
                name: "PriceTarget",
                options: [
                  { value: "0 - 10", label: "0 - 10" },
                  { value: "10 - 20", label: "10 - 20" },
                  { value: "20 - 30", label: "20 - 30" },
                  { value: "30 - 40", label: "30 - 40" },
                  { value: "40 - 50", label: "40 - 50" },
                  { value: "50 - 60", label: "50 - 60" },
                  { value: "60 - 70", label: "60 - 70" },
                  { value: "70 - 80", label: "70 - 80" },
                  { value: "80 - 90", label: "80 - 90" },
                  { value: "90 - 99999999999", label: "90 - 1000+" },
                ],
              };
            } else if (filterOption.name === "Price") {
              return {
                name: "Price",
                options: [
                  { value: "0 - 10", label: "0 - 10" },
                  { value: "10 - 20", label: "10 - 20" },
                  { value: "20 - 30", label: "20 - 30" },
                  { value: "30 - 40", label: "30 - 40" },
                  { value: "40 - 50", label: "40 - 50" },
                  { value: "50 - 60", label: "50 - 60" },
                  { value: "60 - 70", label: "60 - 70" },
                  { value: "70 - 80", label: "70 - 80" },
                  { value: "80 - 90", label: "80 - 90" },
                  { value: "90 - 99999999", label: "90 - 1000+" },
                ],
              };
            } else if (filterOption.name === "Position") {
              return {
                name: "Position",
                options: [
                  { value: "-99999999 - -901", label: "-901 - -99999999" },
                  { value: "-900 - -801", label: "-801 - -900" },
                  { value: "-800 - -701", label: "-701 - -800" },
                  { value: "-700 - -601", label: "-601 - -700" },
                  { value: "-600 - -501", label: "-501 - -600" },
                  { value: "-500 - -401", label: "-401 - -500" },
                  { value: "-400 - -301", label: "-301 - -400" },
                  { value: "-300 - -201", label: "-201 - -300" },
                  { value: "-200 - -101", label: "-101 - -200" },
                  { value: "-100 - -0.1", label: "-0.1 - -100" },
                  { value: "0 - 0", label: "0 - 0" },
                  { value: "0 - 100", label: "0 - 100" },
                  { value: "101 - 200", label: "101 - 200" },
                  { value: "201 - 300", label: "201 - 300" },
                  { value: "301 - 400", label: "301 - 400" },
                  { value: "401 - 500", label: "401 - 500" },
                  { value: "501 - 600", label: "501 - 600" },
                  { value: "601 - 700", label: "601 - 700" },
                  { value: "701 - 800", label: "701 - 800" },
                  { value: "801 - 900", label: "801 - 900" },
                  { value: "901 - 99999999", label: "901 and over" },
                ],
              };
            }
            return filterOption;
          });
        });
      } else {
        throw new Error("An error occurred while fetching filters data");
      }
    } catch (error) {
      toast.remove();
      toast.error("An error occurred while filters data");
    }
  }

  async function handleTradeDelete(id: string[]) {
    try {
      const deleted: any = await axios.delete(`/v1/api/trades`, {
        data: { tradeIds: id },
      });

      if (deleted?.success) {
        toast.success("Trade deleted");
        fetchTradesData(
          {},
          {
            page: paginationState.currentPage,
            limit: paginationState.rowsPerPage,
          }
        );
      }
    } catch (error: any) {
      console.log(error.body.message, "error");
      toast.remove();
      toast.error("An error occurred while deleting trade");
    }
  }

  async function fetchTradesData(filters?: any, queries?: any) {
    if (queries) {
      setPaginationLoader(true);
    } else {
      setIsLoadingTrades(true);
    }
    try {
      const response = await TradesService.tradesControllerFindTrades({
        filters: props?.filter
          ? { ...props?.filter, ...filters }
          : filters || {},
        queries: queries || {},
      });

      if (response.success) {
        let scrappedTrades: TradeType[] = response?.data?.data?.map(
          (trade: any): TradeType => {
            return {
              _id: trade._id,
              status: trade?.status,
              openDate: trade?.entryDate ? trade?.entryDate : "-",
              closeDate: trade?.closeDate ? trade?.closeDate : "-",
              symbol: trade?.symbol ? trade?.symbol : "-",
              size: trade?.size ? trade?.size : 0,
              price: trade?.price ? trade?.price : 0,
              currentPrice: trade?.currentPrice ? trade?.currentPrice : 0,
              exitPrice: trade?.exitPrice ? trade?.exitPrice : 0,
              priceTarget: trade?.priceTarget ? trade?.priceTarget : 0,
              profitTarget: trade?.profitTarget ? trade?.profitTarget : 0,
              returnPrice: trade?.returns ? trade?.returns : 0,
              risk: trade?.risk ? trade?.risk : 0,
              setups: trade?.setups.map((setup: any) => setup.name),
              side: trade?.side,
              createdAt: trade?.createdAt,
            };
          }
        );

        setPaginationState({
          currentPage: response?.data?.currentPage,
          rowsPerPage: response?.data?.limit,
          totalRows: response?.data?.records,
        });
        setTrades(scrappedTrades);
      }
    } catch (error: any) {
      toast.remove();
      toast.error("An error occurred while fetching data");
    }
    setIsLoadingTrades(false);
    setPaginationLoader(false);
  }

  const columns = React.useMemo<ColumnDef<TradeType>[]>(
    () => [
      {
        accessorKey: "select",
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllRowsSelected()}
            onChange={(checked, e) => {
              table.toggleAllRowsSelected(checked);
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onChange={(checked, e) => {
              row.toggleSelected(checked);
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        ),
        size: 50,
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) => <StatusCell info={info} />,
        size: 70,
      },

      {
        accessorKey: "openDate",
        header: "Open Date",
        cell: (info) => <DateCell info={info} />,
        size: 80,
      },

      {
        accessorKey: "closeDate",
        header: "Close Date",
        cell: (info) => <DateCell info={info} />,
        size: 80,
      },

      {
        accessorKey: "symbol",
        header: "Symbol",
        cell: (info) => (
          <p className="text-xs w-[60px] pl-4 text-ellipsis overflow-hidden whitespace-nowrap text-black dark:text-white">
            {info.getValue() as string}
          </p>
        ),
        size: 70,
      },
      {
        accessorKey: "size",
        header: "Size",
        cell: (info) => (
          <p className="text-xs text-black w-[30px] pl-2 text-ellipsis overflow-hidden whitespace-nowrap dark:text-white">
            {info.getValue() as string}
          </p>
        ),
        size: 45,
      },

      {
        accessorKey: "price",
        header: "Avg. Price",
        cell: (info) => (
          <p className="text-xs text-black w-[50px] text-ellipsis overflow-hidden whitespace-nowrap dark:text-white">
            ${info.getValue() as string}
          </p>
        ),
        size: 65,
      },

      // {
      //   accessorKey: "currentPrice",
      //   header: "Current Price",
      //   cell: (info: any) => (
      //     <p className="text-xs text-black dark:text-white">
      //       ${info.getValue().toFixed(2) as string}
      //     </p>
      //   ),
      //   size: 75,
      // },
      // {
      //   accessorKey: "priceTarget",
      //   header: "Price Target",
      //   cell: (info: any) => (
      //     <p className="text-xs text-black dark:text-white">
      //       ${info.getValue().toFixed(2) as string}
      //     </p>
      //   ),
      //   size: 65,
      // },
      {
        accessorKey: "profitTarget",
        header: () => (
          <div className="flex items-center justify-center">
            <p>Profit Target</p>
            <DropdownHeader
              options={["%", "$"]}
              selected={profitTargetUnit}
              onSelect={handleProfitTargetSelect}
            />
          </div>
        ),
        cell: (info: any) => (
          <p className="text-xs text-black w-[60px] text-ellipsis overflow-hidden dark:text-white">
            {profitTargetUnit === "%"
              ? (info?.getValue()?.toFixed(2) as string) + "%"
              : (("$" + info?.getValue()?.toFixed(2)) as string)}
          </p>
        ),
        size: 70,
      },
      {
        accessorKey: "risk",
        header: () => (
          <div className="flex items-center justify-center">
            <p>Risk</p>

            <DropdownHeader
              options={["%", "$"]}
              selected={riskUnit}
              onSelect={handleRiskSelect}
            />
          </div>
        ),
        cell: (info: any) => (
          <p className="text-xs text-black dark:text-white">
            {riskUnit === "%"
              ? info?.getValue()?.toFixed(2) + "%"
              : "$" + info?.getValue()?.toFixed(2)}
          </p>
        ),
        size: 65,
      },

      {
        accessorKey: "exitPrice",
        header: "Exit Price",
        cell: (info: any) => (
          <p className="text-xs text-black w-[40px] text-ellipsis overflow-hidden whitespace-nowrap dark:text-white">
            ${info?.getValue()?.toFixed(2) as string}
          </p>
        ),
        size: 60,
      },

      {
        accessorKey: "returnPrice",
        header: "Return",
        cell: (info: any) => (
          <p
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            className="text-xs text-black w-[50px] text-ellipsis overflow-hidden whitespace-nowrap dark:text-white"
          >
            {info?.row?.original?.status !== "open"
              ? `$${info?.getValue()?.toFixed(2)}`
              : ("-" as string)}
          </p>
        ),
        size: 70,
      },

      {
        accessorKey: "side",
        header: "Side",
        cell: (info) => (
          <p
            className={`font-medium text-xs ${
              info.getValue() === "buy" ? "text-dark-border" : "text-delRed"
            }`}
          >
            {info.getValue() === "buy" ? "LONG" : "SHORT"}
          </p>
        ),
        size: 70,
      },

      {
        accessorKey: "setups",
        header: "Setups",
        cell: (info: any) => (
          <div
            className={`group flex justify-center items-center relative gap-2 py-1 overflow-x-hidden w-full overflow-hidden ${
              info?.getValue().length <= 0 && "!cursor-default"
            } ${
              info.getValue()?.length - 2 >= 1 &&
              "hover:border-x hover:border-black px-2 py-1 transition-all hover:bg-[#0000004d] cursor-pointer"
            } `}
          >
            <SetupCells info={info} />
            {info.getValue()?.length - 2 >= 1 && (
              <>
                <div className="innerDiv opacity-0 group-hover:opacity-100 absolute right-2 text-xs bg-light-background text-black px-1 rounded transition-opacity duration-300">
                  {info.getValue()?.length - 2}+
                </div>
              </>
            )}
          </div>
        ),
        size: 100,
      },
    ],
    [riskUnit, profitTargetUnit]
  );

  return (
    <>
      <div
        className={`font-poppins relative z-40 w-full lg:w-[78%] ${
          props && props?.className
        } overflow-hidden shadow  h-fit bg-white dark:bg-dark-background border dark:border-black dark:text-white rounded-xl`}
      >
        {/* start manipulation table */}
        <div className="px-5 pt-5 w-full flex flex-wrap gap-2 justify-between items-start">
          <h1 className="text-heading_3 md:text-2xl 2xl:text-3xl font-medium dark:text-white">
            {props && props?.title ? props?.title : "Trades Log"}
          </h1>

          <div className="ml-auto self-end">
            <div className="flex relative self-end w-full justify-end items-start gap-2">
              {selectedRows && selectedRows.length > 0 && (
                <>
                  <button
                    onClick={toggleDropdown} // Toggle the div when the button is clicked
                    className="flex justify-center relative z-40 items-center gap-2 px-4 py-1 bg-light-foreground text-white dark:bg-white dark:text-black rounded hover:opacity-80 transition-opacity"
                  >
                    Actions
                  </button>

                  {isOpen && (
                    <div className="absolute z-10 left-0 top-[35px]">
                      <MemoizedActions
                        setSelectedRows={() => {
                          // setSelectedRows([]);
                          setRowSelection({});
                        }}
                        setIsOpen={() => setIsOpen(false)}
                        selectedRows={selectedRows}
                        fetchTradesData={fetchTradesData}
                      />
                    </div>
                  )}
                </>
              )}

              {!hideFilter && (
                <>
                  {!isFilterSelected ? (
                    <>
                      <button
                        onClick={() => setIsFilterSelected(true)}
                        className="flex justify-center items-center gap-2 px-4 py-1 bg-light-foreground text-white rounded hover:opacity-80 transition-opacity dark:bg-white dark:text-black"
                      >
                        Filters <FaFilter />
                      </button>

                      <MemoizedFlyoutComponent
                        shown="click"
                        FlyoutContent={() => (
                          <MemoizedEditColumns
                            columns={columns}
                            setColumnVisibility={setColumnVisibility}
                            columnVisibility={columnVisibility}
                          />
                        )}
                      >
                        <button className="flex justify-center relative z-40 items-center gap-2 px-4 py-1 bg-light-foreground text-white dark:bg-white dark:text-black rounded hover:opacity-80 transition-opacity">
                          Edit Columns
                        </button>
                      </MemoizedFlyoutComponent>
                    </>
                  ) : (
                    <>
                      {filtersOptions.length > 0 ? (
                        <motion.div
                          className="flex justify-end items-center flex-wrap gap-2 relative"
                          initial={{ y: 50, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          exit={{ y: 50, opacity: 0 }}
                          transition={{ duration: 0.3 }}
                        >
                          {filtersOptions.map((fil) => (
                            <FilterPopup
                              key={fil.name}
                              filterName={fil.name}
                              options={fil.options}
                              allSelectedFilters={allSelectedFilters}
                              setAllSelectedFilters={setAllSelectedFilters}
                              searchable={fil.name.toLowerCase() === "symbol"}
                            />
                          ))}
                          <button
                            onClick={() => setIsFilterSelected(false)}
                            className="bg-light-foreground text-white dark:bg-white dark:text-black hover:opacity-80 transition-opacity p-2 rounded-md cursor-pointer"
                          >
                            <TbArrowForwardUp
                              size={20}
                              className="text-white dark:text-black"
                            />
                          </button>
                        </motion.div>
                      ) : (
                        <div className="flex items-center justify-between py-3 min-w-[50px]">
                          <TailSpin
                            width={"28px"}
                            height={"28px"}
                            color={theme === "light" ? "#36454F" : "#3A6FF8"}
                          />
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {/* end manipulation table */}

        {/* all selected filters */}
        <div className="flex p-5 flex-wrap relative">
          {allSelectedFilters.map((selectedFilter) => (
            <div
              key={selectedFilter.value}
              className="justify-center gap-1 flex items-center bg-gray-200 text-gray-700 text-xs font-medium mr-2 mb-2 px-2 py-1 rounded"
            >
              <div className="text-xs">{selectedFilter.value}</div>
              <button
                type="button"
                onClick={() => handleTagRemove(selectedFilter.value)}
                className="ml-1 bg-transparent text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <IoIosClose size={18} />
              </button>
            </div>
          ))}
        </div>

        {isLoadingTrades ? (
          <>
            <div className="flex justify-center items-center w-full h-full min-h-[480px]">
              <TailSpin
                width={"54px"}
                height={"54px"}
                color={theme === "light" ? "#36454F" : "#3A6FF8"}
              />
            </div>
          </>
        ) : (
          <>
            <Table
              hideDelete={props?.trades ? true : false}
              tableData={trades || []}
              columns={columns.filter(
                (column: any) => columnVisibility[column.accessorKey as string]
              )}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              handleDeleteTrade={handleTradeDelete}
              onSelectionChange={(selectedIds) => {
                setSelectedRows(selectedIds);
              }}
            />
          </>
        )}
        <div
          className={`w-full flex ${
            props && props.title === "Open Trades"
              ? "justify-end"
              : "justify-between"
          }  justify-between items-center gap-3 p-5 bg-white dark:bg-dark-background border dark:border-black dark:text-white rounded-b-xl`}
        >
          {(props && props.title === "Open Trades") ||
          hideFilter ||
          (props?.trades && props?.trades?.length > 0) ? (
            <></>
          ) : (
            <div>
              <Link
                to="/trades/manual"
                className="btn-tertiary !font-poppins !text-dark-border !border-dark-border flex justify-center items-center text-[.5rem] sm:text-[.6rem] md:text-[.9rem] gap-2"
              >
                <FaPlus /> ADD TRADE
              </Link>
            </div>
          )}
          <div>
            {paginationState?.totalRows &&
            paginationState?.totalRows > 0 &&
            !props?.trades ? (
              <PaginationComponent
                totalRows={paginationState.totalRows}
                rowsPerPage={paginationState.rowsPerPage}
                rowsPerPageOptions={generateRowsPerPageOptions(
                  paginationState?.totalRows
                )}
                currentPage={paginationState.currentPage}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                setPaginationState={setPaginationState}
                paginationLoader={paginationLoader}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default TradesLogTable;
