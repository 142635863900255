import { useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

const Invoice = () => {
  const [showPdf, setShowPdf] = useState<boolean>(false);

  // Example state data to pass to PdfFile component
  const stateData = {
    amount: 10,
    currency: "usd",
    createdAt: "2024-09-23T05:40:24.561+00:00",
    nextPaymentDate: "2024-10-23T05:40:24.561+00:00",
  };

  return (
    <>
      <div className="w-full flex items-center justify-center">
        <button
          onClick={() => setShowPdf(!showPdf)}
          className="border py-3 px-4 bg-green-500/30"
        >
          View State
        </button>
      </div>
      {showPdf && <PdfFile state={stateData} />}{" "}
    </>
  );
};

export default Invoice;

// Define styles for the PDF document
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontFamily: "Helvetica",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  heading: {
    fontSize: 20,
    marginBottom: 20,
    textAlign: "center",
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
  },
  boldText: {
    fontSize: 14,
    fontWeight: "bold",
  },
});

// PdfFile component to render the PDF content
export const PdfFile = ({ state }: { state: any }) => {
  const { previousInvoice, currentInvoice } = state;

  function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Current Package Invoice */}
        <View style={styles.section}>
          <Text style={styles.heading}>
            Current Invoice - {formatDate(currentInvoice.createdAt)}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.boldText}>
            Amount: {currentInvoice.currency.toUpperCase()}{" "}
            {currentInvoice.amount}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.boldText}>
            Buy Date: {formatDate(currentInvoice.createdAt)}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.boldText}>
            Next Payment Date: {formatDate(currentInvoice.nextPaymentDate)}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.boldText}>
            Status:{" "}
            {currentInvoice.status === "active" ? "Active" : "Cancelled"}
          </Text>
        </View>

        {/* Previous Package Invoice, if exists */}
        {previousInvoice && (
          <>
            <View style={styles.section}>
              <Text style={styles.heading}>
                Previous Invoice - {formatDate(previousInvoice.createdAt)}
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.boldText}>
                Amount: {previousInvoice.currency.toUpperCase()}{" "}
                {previousInvoice.amount}
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.boldText}>
                Buy Date: {formatDate(previousInvoice.createdAt)}
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.boldText}>
                Next Payment Date: {formatDate(previousInvoice.nextPaymentDate)}
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.boldText}>
                Status:{" "}
                {previousInvoice.status === "active" ? "Active" : "Cancelled"}
              </Text>
            </View>
          </>
        )}
      </Page>
    </Document>
  );
};
