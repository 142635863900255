const ErrorFallback = ({
  error,
  resetError,
  className,
}: {
  error: any;
  resetError: () => void;
  className?: string;
}) => {
  return (
    <div
      className={`flex justify-center items-center w-full h-full ${className}`}
    >
      <div className="bg-white dark:bg-dark-background p-6 rounded-lg shadow-lg max-w-md w-full">
        <div className="flex flex-col items-center text-center">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-2">
            Oops! Something went wrong.
          </h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            An unexpected error has occurred.
          </p>
          <pre className="bg-gray-100 dark:bg-dark-backgroundMain text-red-500 p-2 rounded-md mb-4 w-full overflow-auto">
            {error.message}
          </pre>
          <button
            onClick={resetError}
            className="bg-light-foreground hover:opacity-85 dark:bg-dark-border text-white font-bold py-2 px-4 rounded"
          >
            Try again
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
