import moment from "moment-timezone";
import {
  dateFormats,
  popularLanguages,
} from "../data/select-options/select-options";
import {
  SelectedFilterOption,
  TradeType,
} from "../pages/app/trades/tradeslog/components/TradesLogTable/TradesLogTable";
import { spread } from "axios";

export function toTitleCase(str: string): string {
  return str?.replace(
    /\w\S*/g,
    (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
  );
}

// Function to format date
export const formatDate = (
  date: string | Date,
  format: string = "YYYY-MM-DD HH:mm:ss",
  time?: boolean
) => {
  let formattedDate = moment(date).format(`${format} ${time ? "hh:mm A" : ""}`);
  if (formattedDate === "Invalid date") {
    return "-";
  }

  return formattedDate;
};

// Function to convert date to a specific timezone
export const convertToTimezone = (
  date: string | Date,
  timezone: string,
  format: string = "YYYY-MM-DD HH:mm:ss"
) => {
  return moment(date).tz(timezone).format(format);
};

//Find and select language
export function selectDefaultLanguage(language: string) {
  let selectedLanguage;
  if (language !== "" || language !== null || language !== undefined) {
    selectedLanguage = popularLanguages.find((lan) => lan.value === language);
  }

  if (selectedLanguage === undefined) {
    selectedLanguage = popularLanguages.find((lan) => lan.value === "EN");
  }

  return selectedLanguage;
}

export function selectDefaultTimezone(timezone: string, allTimezones: any) {
  let selectedTimezone;

  if (timezone !== "" || timezone !== null || timezone !== undefined) {
    selectedTimezone = allTimezones.find((tim: any) => tim.value === timezone);
  }

  if (selectedTimezone === undefined) {
    selectedTimezone = allTimezones.find((tim: any) => tim.abbrev === "GMT");
  }

  return { label: selectedTimezone.label, value: selectedTimezone.abbrev };
}

export function selectDefaultDateFormat(dateFormat: string) {
  let selectedDateFormat;

  if (dateFormat !== "" || dateFormat !== null || dateFormat !== undefined) {
    selectedDateFormat = dateFormats.find(
      (date: any) => date.value === dateFormat
    );
  }

  if (selectedDateFormat === undefined) {
    selectedDateFormat = dateFormats.find(
      (date: any) => date.value === "MM/DD/YYYY"
    );
  }

  return selectedDateFormat;
}

export function selectDefaultCountry(userData: any, countries: any) {
  let defaultCountry = countries?.find((cot: any) => {
    if (userData?.country !== "") {
      return cot.isoCode === userData?.country;
    } else {
      return cot.isoCode === "US";
    }
  });

  return { label: defaultCountry?.name, value: defaultCountry?.isoCode };
}

export function getRandomDate(start: string, end: string, format: string) {
  const startDate = moment(start).valueOf();
  const endDate = moment(end).valueOf();
  const randomDate = new Date(
    startDate + Math.random() * (endDate - startDate)
  );
  return formatDate(randomDate, format);
}

// Helper function to generate dynamic rows per page options
export const generateRowsPerPageOptions = (totalRows: number) => {
  const predefinedOptions = [10, 25, 50, 100];
  const options = [];

  for (let option of predefinedOptions) {
    if (option <= totalRows) {
      options.push(option);
    } else {
      break;
    }
  }

  // Ensure to include the totalRows as the last option if it's not already in the list
  if (!options.includes(totalRows)) {
    options.push(totalRows);
  }

  return options;
};

// generate api payload filters
export const generateApiPayloadFilters = (
  selectedFilters: SelectedFilterOption[]
) => {
  const payload: { [key: string]: (string | number)[] } = {};

  selectedFilters.forEach((filter) => {
    const camelCaseName = toCamelCase(filter.name);
    const { value } = filter;
    if (!payload[camelCaseName]) {
      payload[camelCaseName] = [];
    }
    payload[camelCaseName].push(value);
  });

  return payload;
};

export const toCamelCase = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w|\s+|[_-])/g, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase()
    )
    .replace(/[\s+|_|-]/g, "");
};

export function scrapeTradeOrders(tradeOrders: Partial<TradeType>[]): any {
  if (!tradeOrders) return;

  const scrappedOrders = tradeOrders.map((order) => {
    return {
      _id: order?._id as string,
      action: order?.side,
      type: order?.type,
      spread: order?.spread ? order?.spread : null,
      expire: order?.expire ? order?.expire : null,
      strike: order?.strike ? order?.strike : null,
      openDate: order?.entryDate,
      size: order?.size,
      position: order?.position,
      price: order?.price,
      value: order?.value,
      return: order?.returns,
      commission: order?.commission,
      fees: order?.fees,
      returnPercentage:
        order?.netReturns && order?.value
          ? (order?.netReturns / order?.value) * 100
          : 0,
      netReturns: order?.netReturns,
    };
  });

  return scrappedOrders;
}

// Debounce function
// debounce.ts
export function debounce(func: (...args: any[]) => void, wait: number) {
  let timeout: NodeJS.Timeout;
  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export function formatNumber(value: number) {
  const absoluteValue = Math.abs(value); // Get the absolute value to handle negative numbers
  let formattedValue;

  if (absoluteValue >= 1000000000) {
    formattedValue = `${(absoluteValue / 1000000000).toFixed(1)}B`;
  } else if (absoluteValue >= 1000000) {
    formattedValue = `${(absoluteValue / 1000000).toFixed(1)}M`;
  } else if (absoluteValue >= 1000) {
    formattedValue = `${(absoluteValue / 1000).toFixed(1)}K`;
  } else {
    formattedValue = absoluteValue.toFixed(1);
  }

  // Return the value with its original sign
  return value < 0 ? `-${formattedValue}` : formattedValue;
}

export function getUploadPath(
  userId: string | undefined,
  feature: string,
  featureId: any,
  filePath: string
) {
  // Define a base path for the S3 bucket
  const basePath = `${userId}/${feature}/${featureId}`;
  // Construct the full upload path
  const uploadPath = `${basePath}/${filePath}`;

  return uploadPath;
}

export enum TradeStorage {
  Max = 500,
}
