import React from "react";
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi";
import { TailSpin } from "react-loader-spinner";
import { useTheme } from "../../../../../../../../../context/ThemeContext";

type PaginationProps = {
  totalRows: number;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
  currentPage: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (rowsPerPage: number) => void;
  setPaginationState: React.Dispatch<
    React.SetStateAction<{
      currentPage: number;
      rowsPerPage: number;
      totalRows: number;
    }>
  >;
  paginationLoader: boolean;
};

const PaginationComponent: React.FC<PaginationProps> = ({
  totalRows,
  rowsPerPage,
  rowsPerPageOptions,
  currentPage,
  onPageChange,
  onRowsPerPageChange,
  paginationLoader,
}) => {
  const { theme } = useTheme();

  const totalPages = Math.ceil(totalRows / rowsPerPage);

  const handleFirstPage = () => onPageChange(1);
  const handlePreviousPage = () => onPageChange(Math.max(currentPage - 1, 1));
  const handleNextPage = () =>
    onPageChange(Math.min(currentPage + 1, totalPages));
  const handleLastPage = () => onPageChange(totalPages);

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  return (
    <>
      {paginationLoader ? (
        <div className="flex items-center justify-between py-3 min-w-[50px]">
          <TailSpin
            width={"28px"}
            height={"28px"}
            color={theme === "light" ? "#36454F" : "#3A6FF8"}
          />
        </div>
      ) : (
        <>
          {" "}
          <div className="flex items-center justify-between py-2">
            <div className="flex items-center gap-2">
              <span className="font-medium text-[.5rem] sm:text-[.6rem] md:text-[.9rem]">
                Rows per page
              </span>
              <select
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
                className="border dark:text-black rounded px-2 py-1 cursor-pointer"
              >
                {rowsPerPageOptions.map((option) => (
                  <option
                    className="text-[.5rem] sm:text-[.6rem] md:text-[.9rem]"
                    key={option}
                    value={option}
                  >
                    {option}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex items-center space-x-2 mx-2">
              <span className="text-[.5rem] sm:text-[.6rem] md:text-[.9rem]">
                {rowsPerPage * (currentPage - 1) + 1}-
                {Math.min(rowsPerPage * currentPage, totalRows)} of {totalRows}
              </span>

              <button
                onClick={handleFirstPage}
                disabled={currentPage === 1}
                className="p-2 hover:opacity-80  transition-opacity disabled:text-gray-300 disabled:opacity-100"
              >
                <FiChevronsLeft size={22} />
              </button>
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="p-2  hover:opacity-80 transition-opacity disabled:text-gray-300 disabled:opacity-100"
              >
                <FiChevronLeft size={22} />
              </button>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className="p-2 hover:opacity-80 transition-opacity disabled:text-gray-300 disabled:opacity-100"
              >
                <FiChevronRight size={22} />
              </button>
              <button
                onClick={handleLastPage}
                disabled={currentPage === totalPages}
                className="p-2 hover:opacity-80 transition-opacity disabled:text-gray-300 disabled:opacity-100"
              >
                <FiChevronsRight size={22} />
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PaginationComponent;
