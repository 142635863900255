import { ColumnDef } from "@tanstack/react-table";
import { TradeType } from "../../TradesLogTable";

// EditColumns component (simplified example)
type EditColumnsProps = {
  columns: ColumnDef<TradeType>[];
  columnVisibility: { [key: string]: boolean };
  setColumnVisibility: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
};

const EditColumns: React.FC<EditColumnsProps> = ({
  columns,
  columnVisibility,
  setColumnVisibility,
}) => {
  const handleCheckboxChange = (accessorKey: string) => {
    setColumnVisibility((prev) => ({
      ...prev,
      [accessorKey]: !prev[accessorKey],
    }));
  };

  return (
    <div className="font-poppins bg-light-foreground dark:bg-grayMain max-h-[200px] overflow-auto dark:text-dark-background text-white py-2 px-3 w-[200px] flex flex-col gap-2 shadow-md rounded">
      {columns.slice(1).map((column: any, idx: number) => {
        return (
          <div
            key={column.accessorKey as string}
            className={`flex justify-start items-center py-1 text-sm ${
              idx !== columns?.length - 1 &&
              "border-b border-grayMain dark:border-light-border"
            }`}
          >
            <label className="cursor-pointer flex justify-center items-center">
              <input
                type="checkbox"
                checked={columnVisibility[column.accessorKey as string]}
                onChange={() =>
                  handleCheckboxChange(column.accessorKey as string)
                }
              />
              <span className="ml-2">
                {column.accessorKey === "profitTarget"
                  ? "Profit Target"
                  : column.accessorKey === "risk"
                  ? "Risk"
                  : column.header}
              </span>
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default EditColumns;
