/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTradesJournalDTO } from '../models/CreateTradesJournalDTO';
import type { DeleteImageDTO } from '../models/DeleteImageDTO';
import type { TradeFilterAndQueries } from '../models/TradeFilterAndQueries';
import type { UpdateTradesJournalDTO } from '../models/UpdateTradesJournalDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class JournalService {
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static tradesJournalControllerFindTrades(
        requestBody: TradeFilterAndQueries,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/api/journal/find',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static tradesJournalControllerCreateJournal(
        requestBody: CreateTradesJournalDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/api/journal',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param journalId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static tradesJournalControllerUpdateJournal(
        journalId: string,
        requestBody: UpdateTradesJournalDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/api/journal/{journalId}',
            path: {
                'journalId': journalId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param journalId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static tradesJournalControllerDeleteImage(
        journalId: string,
        requestBody: DeleteImageDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/api/journal/{journalId}/image',
            path: {
                'journalId': journalId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param date
     * @returns any
     * @throws ApiError
     */
    public static tradesJournalControllerGetJournalByDate(
        date: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/api/journal/{date}',
            path: {
                'date': date,
            },
        });
    }
}
