import moment from "moment";
import { images } from "../../../../assets/images";
import { JournalType } from "../Journal";
import { formatNumber } from "../../../../utils/utils";

const CalendarWithNotes = ({
  key,
  setOpenSidePannel,
  journal,
  setViewJournal,
}: {
  key: number;
  setOpenSidePannel: (value: boolean) => void;
  journal: JournalType;
  setViewJournal: (value: JournalType) => void;
}) => {
  return (
    <div key={key} className="bg-white dark:bg-dark-background rounded-lg">
      {/* Upper Div */}
      <div className="flex justify-between items-center border-b border-gray-300 dark:border-dark-border px-4 py-3">
        {/* Date Section */}
        <h3 className="text-xs font-semibold text-[#36454F] dark:text-dark-border">
          {moment(journal.date).format("MMM DD, ddd YYYY")}
        </h3>

        {/* View Details Section */}
        <div
          className="text-xs text-[#36454F] dark:text-dark-border flex gap-1 cursor-pointer"
          onClick={() => {
            setOpenSidePannel(true);
            setViewJournal(journal);
          }}
        >
          <span>{images.ViewDetails("")}</span>
          <span>View Details</span>
        </div>
      </div>

      {/* Lower Div */}
      <div className="flex justify-between gap-20 py-6 pl-4 pr-10">
        {/* Left Section */}
        <div className="flex gap-14 justify-between flex-1">
          {/* Left Part: Amount */}
          <div className="text-2xl w-max text-[#3CB371] dark:text-green-400">
            {`$ ${formatNumber(Number(journal?.totalReturn?.toFixed(2)))}`}
          </div>

          {/* Right Part: Stats Section */}
          <div className="flex flex-1 gap-20 justify-between">
            {/* Stat Row */}
            <div className="flex flex-col gap-4">
              <div className="flex justify-between gap-20">
                <span className="text-xs text-[#36454F] dark:text-white">
                  Return $
                </span>
                <span className="text-xs text-[#929292] dark:text-white/60">
                  {`$ ${formatNumber(
                    Number(journal?.totalReturn?.toFixed(2))
                  )}`}
                </span>
              </div>
              <div className="flex justify-between gap-20">
                <span className="text-xs text-[#36454F] dark:text-white">
                  Commissions
                </span>
                <span className="text-xs text-[#929292] dark:text-white/60">
                  {`$ ${formatNumber(
                    Number(journal?.totalCommission?.toFixed(2))
                  )}`}
                </span>
              </div>
              <div className="flex justify-between gap-20">
                <span className="text-xs text-[#36454F] dark:text-white">
                  Net Return $
                </span>
                <span className="text-xs text-[#929292] dark:text-white/60">
                  {`$ ${formatNumber(
                    Number(journal?.totalNetReturn?.toFixed(2))
                  )}`}
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex justify-between gap-20">
                <span className="text-xs text-[#36454F] dark:text-white">
                  Win %
                </span>
                <span className="text-xs text-[#929292] dark:text-white/60">
                  {`${journal.winPercentage?.toFixed(2)}%`}
                </span>
              </div>
              <div className="flex justify-between gap-20">
                <span className="text-xs text-[#36454F] dark:text-white">
                  Profit Factor
                </span>
                <span className="text-xs text-[#929292] dark:text-white/60">
                  {` ${journal.profitFactor?.toFixed(2)}`}
                </span>
              </div>
              <div className="flex justify-between gap-20">
                <span className="text-xs text-[#36454F] dark:text-white">
                  Trades
                </span>
                <span className="text-xs text-[#929292] dark:text-white/60">
                  {` ${journal.tradeCount}`}
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex justify-between gap-20">
                <span className="text-xs text-[#36454F] dark:text-white">
                  MFE
                </span>
                <span className="text-xs text-[#929292] dark:text-white/60">
                  {` ${
                    journal?.maxMFE === null
                      ? "-"
                      : "$" + formatNumber(Number(journal?.maxMFE?.toFixed(2)))
                  }`}
                </span>
              </div>
              <div className="flex justify-between gap-20">
                <span className="text-xs text-[#36454F] dark:text-white">
                  MAE
                </span>
                <span className="text-xs text-[#929292] dark:text-white/60">
                  {`${
                    journal?.maxMAE === null
                      ? "-"
                      : "$" + formatNumber(Number(journal?.maxMAE?.toFixed(2)))
                  }`}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="w-1/3">
          <h4 className="text-md font-semibold text-[#36454F] dark:text-dark-border">
            Notes
          </h4>
          <p
            dangerouslySetInnerHTML={{
              __html: journal?.journal?.notes || "",
            }}
            className="text-xs text-[#929292] dark:text-white/60 mt-1"
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarWithNotes;
