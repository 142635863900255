import { CSSProperties, useEffect, useRef, useState } from "react";
import styles from "./FormFields.module.scss";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import MessageIcon from "../../assets/icons/Message.svg";
import LockIcon from "../../assets/icons/Lock.svg";
import { Country } from "country-state-city";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ReactSelect, { StylesConfig } from "react-select";
import ReactSelectCreatable from "react-select/creatable";
import { useTheme } from "../../context/ThemeContext";
import OTPInput from "react-otp-input";
import { FaExclamationTriangle } from "react-icons/fa";
import { FaCircleCheck } from "react-icons/fa6";
import { images } from "../../assets/images";
import DateTimePicker from "react-datetime-picker";
import DatePicker from "react-date-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

export const TextField = ({
  autoFocus = false,
  error,
  inline,
  label,
  textarea,
  textFieldDivCss,
  rewrite,
  labelClass,
  addressField,
  onPlaceSelected,
  value,
  inputType,
  required,
  isFormField,
  className,
  ...props
}: {
  [key: string]: any;
  value: string;
  autoFocus?: boolean;
  error?: any;
  inline?: string;
  label?: string;
  textarea?: string;
  textFieldDivCss?: string;
  rewrite?: string;
  labelClass?: string;
  addressField?: string;
  onPlaceSelected?: string;
  inputType?: string;
  isFormField?: boolean;
  required?: boolean;
  className?: string;
}) => {
  const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);

  const handleParentDivClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div
      className={`${styles.row} ${styles["input-container"]} w-full ${
        error ? styles.error : ""
      } ${textFieldDivCss} ${rewrite && ""}`}
    >
      <div
        className={`relative bg-white flex justify-center items-center gap-1 dark:bg-dark-background dark:text-dark-primary ${
          isFormField && !label && "mt-[2.12rem]"
        } ${className} font-normal rounded bg-transparent ${
          inputType === "email" ? "py-2" : "py-[13.39px]"
        }  px-6 text-black outline-none border-2 focus-within:border-light-secondary  dark:focus-within:border-dark-foreground focus-within:shadow-lg transition-shadow ${
          error
            ? "border-red-500 focus-within:border-red-500"
            : "border-stroke border-[#00336653] dark:border-dark-secondary"
        }`}
        onClick={handleParentDivClick}
      >
        {label && (
          <label
            className={`absolute block font-normal bg-white px-2 left-4 -top-4 text-light-primary dark:bg-dark-background dark:text-dark-primary ${labelClass}`}
          >
            {label}
            {required && <span className="text-red-600 -mt-5 p-1">*</span>}
          </label>
        )}
        {inputType === "email" && (
          <img
            src={MessageIcon}
            className="w-8 mt-[2px] dark:brightness-200 dark:contrast-50"
          />
        )}
        {textarea ? (
          <textarea
            {...props}
            ref={inputRef}
            value={value}
            className="outline-none border-none w-full placeholder:text-light-secondary dark:bg-dark-background dark:placeholder:text-dark-secondary"
          />
        ) : (
          <input
            {...props}
            ref={inputRef}
            type={inputType}
            value={value}
            autoFocus={autoFocus}
            className={`${className} outline-none border-none w-full placeholder:text-light-secondary dark:bg-dark-background dark:placeholder:text-dark-secondary`}
          />
        )}
      </div>

      {typeof error === "string" && (
        <div className="text-red-500 text-sm font-normal my-1">{error}</div>
      )}
    </div>
  );
};

export const PasswordField = ({
  label,
  error,
  value = "",
  validator,
  inline,
  black,
  required,
  labelClass,
  isFormField,
  className,
  ...props
}: {
  label?: string;
  error?: string | false | undefined;
  value?: string;
  validator?: any;
  inline?: string;
  black?: string;
  required?: boolean;
  labelClass?: string;
  isFormField?: boolean;
  className?: string;
  [key: string]: any;
}) => {
  const [toggle, setToggle] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleParentDivClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="w-full">
      <div className={`${styles.row} w-full ${error ? styles.error : ""}`}>
        {isFormField && !label && <span className="mb-[2.12rem]"></span>}
        <div
          className={`relative flex justify-center items-center gap-1 bg-white dark:bg-dark-background dark:text-dark-primary ${
            isFormField && !label && "mt-[2.12rem]"
          } font-normal rounded bg-transparent py-2 px-6 text-black outline-none border-2 focus-within:border-light-secondary dark:focus-within:border-dark-foreground focus-within:shadow-lg transition-shadow ${
            error
              ? "border-red-500 focus-within:!border-red-500"
              : "border-stroke border-[#00336653] dark:border-dark-secondary"
          }`}
          onClick={handleParentDivClick}
        >
          {label && (
            <label
              className={`absolute  block font-normal bg-white px-2 left-4 -top-4 text-light-primary dark:bg-dark-background dark:text-dark-primary ${labelClass}`}
            >
              {label}
              {required && <span className="text-red-600 -mt-5 p-1">*</span>}
            </label>
          )}
          <img
            src={LockIcon}
            className="w-8 mt-[2px] dark:brightness-200 dark:contrast-50"
          />
          <input
            {...props}
            ref={inputRef}
            value={value}
            type={toggle ? "text" : "password"}
            className={`${className} bg-white outline-none border-none w-full placeholder:text-light-secondary dark:bg-dark-background dark:placeholder-dark-secondary`}
          />
          <span
            className="absolute right-5 cursor-pointer"
            onClick={() => setToggle(!toggle)}
          >
            {toggle ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
          </span>
        </div>
        {validator !== true && typeof error === "string" && (
          <div className="text-red-500 text-sm font-normal my-1">{error}</div>
        )}
      </div>
      {validator && (
        <div
          className={`mt-2 flex items-center justify-start gap-2 lg:gap-4 text-light-foreground dark:text-dark-primary`}
        >
          <div className="flex items-center justify-center gap-2">
            {value?.length > 7 ? (
              <FaCircleCheck className="text-green-700" />
            ) : (
              <FaExclamationTriangle className="text-red-600" />
            )}
            <span className="text-xs">8-25 Char</span>
          </div>
          <div className="flex items-center justify-center gap-2">
            {checkUppercase(value) ? (
              <FaCircleCheck className="text-green-700" />
            ) : (
              <FaExclamationTriangle className="text-red-600" />
            )}
            <span className="text-xs">1 Upper Case</span>
          </div>
          <div className="flex items-center justify-center gap-2">
            {/\d/.test(value) ? (
              <FaCircleCheck className="text-green-700" />
            ) : (
              <FaExclamationTriangle className="text-red-600" />
            )}
            <span className="text-xs">1 Number</span>
          </div>
          <div className="flex items-center justify-center gap-2">
            {/[!@#$%^&*(),.?":{}|<>]/.test(value) ? (
              <FaCircleCheck className="text-green-700" />
            ) : (
              <FaExclamationTriangle className="text-red-600" />
            )}
            <span className="text-xs">1 Special Char</span>
          </div>
        </div>
      )}
    </div>
  );
};

const checkUppercase = (value: string) => /[A-Z]/.test(value);

export const PhoneFieldMain = ({
  width,
  error,
  label,
  labelClass,
  required,
  data,
  value,
  isFormField,
  formikHandleChange,
  placeholder,
  name,
  className,
  borderOnFocusClass,
  borderClass,
  labelInField,
  //@ts-ignore
  ...props
}: {
  width?: string;
  error?: string | false | undefined;
  label?: string;
  labelClass?: string;
  formikHandleChange: (value: string | undefined) => void;
  required?: boolean;
  data?: any;
  value: any;
  isFormField?: boolean;
  placeholder?: string;
  name: string;
  className?: string;
  borderOnFocusClass: string;
  borderClass?: string;
  labelInField: boolean;
  [key: string]: any;
}) => {
  // const { setFieldValue } = useFormikContext<UserDataEditForm>();

  const focusRef = useRef<HTMLInputElement>(null);

  // setFieldValue(name!, value);

  const handleParentDivClick = () => {
    if (focusRef.current) {
      focusRef.current.focus();
    }
  };

  const handleLabelClick = (event: React.MouseEvent<HTMLLabelElement>) => {
    event.stopPropagation();
    if (focusRef.current) {
      focusRef.current.focus();
    }
  };

  return (
    <>
      <div
        className={`${styles.row} ${width ? width : "w-full"} ${
          error ? styles.error : ""
        } `}
        onClick={handleParentDivClick}
      >
        {label && labelInField === false && (
          <label
            className={`block mb-2 font-normal bg-transparent w-fit px-2 text-light-primary dark:bg-dark-background dark:text-dark-primary ${labelClass}`}
            htmlFor={name}
            onClick={handleLabelClick}
          >
            {label}
            {required && <span className="text-red-600 -mt-5 p-1">*</span>}
          </label>
        )}
        {isFormField && !label && <span className="mb-[2.12rem]"></span>}
        <div
          className={`relative bg-white flex justify-center items-center gap-1 dark:bg-dark-background dark:text-dark-primary ${
            isFormField && !label && "mt-[2.12rem]"
          } font-normal bg-transparent py-[13.39px] px-6 text-black outline-none ${borderOnFocusClass}  ${
            error
              ? "border-red-500 focus-within:!border-red-500 border"
              : `border-stroke ${borderClass}`
          }`}
        >
          {label && labelInField && (
            <label
              className={`absolute block font-normal bg-white px-2 left-4 -top-4 text-light-primary dark:text-dark-primary dark:bg-dark-background ${labelClass}`}
              onClick={handleLabelClick}
            >
              {label}
              {required && <span className="text-red-600 -mt-5 p-1">*</span>}
            </label>
          )}
          <PhoneInput
            className={`w-full ml-1 flex justify-start items-center placeholder:text-light-secondary dark:text-dark-primary dark:placeholder-dark-secondary`}
            placeholder={placeholder}
            onChange={formikHandleChange}
            onCountryChange={(country) =>
              formikHandleChange(
                `+${Country?.getCountryByCode(country ?? "")?.phonecode}`
              )
            }
            value={value}
            defaultCountry="US"
            disabled={props.isDisabled}
            // inputRef={focusRef} // Assign the ref to PhoneInput
          />
        </div>
        {typeof error === "string" && (
          <div className="text-red-500 text-sm font-normal my-1">{error}</div>
        )}
      </div>
    </>
  );
};

export const MultiSelect = ({
  error,
  label,
  labelClass,
  defaultValue,
  className,
  isStripeForm,
  required,
  value,
  isFormField,
  options,
  name,
  placeholder,
  clearable,
  searchable,
  isMulti,
  isCreatable,
  borderOnFocusClass,
  borderClass,
  labelInField,
  formikHandleChange,
  ...props
}: {
  error?: any;
  defaultValue?: any;
  label?: string;
  labelClass?: string;
  required?: boolean;
  value: any;
  isFormField?: boolean;
  isStripeForm?: boolean;
  options?: any[];
  name?: string;
  placeholder?: string;
  clearable?: boolean;
  searchable?: boolean;
  isMulti?: true | false;
  isCreatable?: boolean;
  className?: string;
  borderOnFocusClass: string;
  borderClass?: string;
  labelInField: boolean;
  formikHandleChange?: (value: any, actionMeta?: any) => void;
  [key: string]: any;
}) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const selectRef = useRef<any>(null);

  const { theme } = useTheme();

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const customStyles: StylesConfig<any, true> = {
    placeholder: (styles) => ({
      ...styles,
      color: props.isDisabled
        ? "rgb(156 163 175 / 1)"
        : theme === "light"
        ? "#00336680"
        : "#FFFFFF80",
    }),
    valueContainer: (styles) => ({
      ...styles,
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "&:hover": {
        cursor: "text",
      },
      scrollBehavior: "smooth",
    }),

    singleValue: (styles) => ({
      ...styles,
      color: props.isDisabled
        ? "rgb(156 163 175 / 1)"
        : value?.value === "" || value?.length === 0
        ? theme === "light"
          ? "#00336680"
          : "#FFFFFF80"
        : theme === "light"
        ? "black"
        : "white",
    }),

    control: (styles, state) => ({
      ...styles,
      border: state.isFocused ? 0 : 0,
      backgroundColor: theme === "light" ? "white" : "#393939",
      width: "100%",
      outline: "none",
      boxShadow: "none",
      "&:hover": {
        border: state.isFocused ? 0 : 0,
      },
      "&:focus": {
        border: state.isFocused ? 0 : 0,
      },
      "&:selected": {
        border: state.isFocused ? 0 : 0,
      },
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: theme === "light" ? "white" : "#393939", // Set the background color of the dropdown
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add some shadow to make it pop a bit
      border: "1px solid #ccc", // Add a border
      borderRadius: "4px", // Round the corners
      marginTop: "2px", // Add a little space between the control and the menu
      padding: "4px 0", // Add some padding inside the dropdown
      color: theme === "light" ? "black" : "white",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected
        ? theme === "light"
          ? "#80808071"
          : "#3A6FF8" // This sets the focused background color for dark theme
        : undefined, // Background color for non-focused options in dark theme
      color: props.isDisabled
        ? "rgb(156 163 175 / 1)"
        : isSelected
        ? theme === "light"
          ? "black"
          : "white"
        : undefined,

      ":active": {
        ...styles[":active"],
        backgroundColor: !isSelected && !isFocused ? "#272727" : undefined,
      },
      ":hover": {
        ...styles[":hover"],
        backgroundColor: theme === "light" ? "#80808071" : "#3A6FF8", // Changed to black for hover in dark theme
        color: props.isDisabled ? "rgb(156 163 175 / 1)" : "black", // Ensures text color is red when disabled
      },
    }),
    input: (styles) => ({
      ...styles,
      color: props.isDisabled
        ? "rgb(156 163 175 / 1)"
        : theme === "light"
        ? "black"
        : "white", // Set your desired text color here
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: props.isDisabled
        ? "rgb(156 163 175 / 1)"
        : theme === "light"
        ? "#36454F"
        : "#66B2B2",
      "&:hover": {
        color: props.isDisabled
          ? "rgb(156 163 175 / 1)"
          : theme === "light"
          ? "#36454F"
          : "#66B2B2",
      },
    }),
    clearIndicator: (styles) => ({
      ...styles,
      color: props.isDisabled
        ? "rgb(156 163 175 / 1)"
        : theme === "light"
        ? "#36454F"
        : "#66B2B2",
      "&:hover": {
        color: props.isDisabled
          ? "rgb(156 163 175 / 1)"
          : theme === "light"
          ? "#36454F"
          : "#66B2B2",
      },
    }),
  };

  function handleParentDivClick() {
    if (selectRef.current) {
      selectRef.current.focus();
    }
  }

  return (
    <>
      <div
        className={`${styles.row} w-full ${styles["input-container"]} ${
          error ? styles.error : ""
        }`}
      >
        {label && labelInField === false && (
          <label
            className={`block mb-2 font-normal bg-transparent w-fit px-2 text-light-primary dark:bg-dark-background dark:text-dark-primary ${labelClass}`}
            htmlFor={name}
          >
            {label}
            {required && <span className="text-red-600 -mt-5 p-1">*</span>}
          </label>
        )}
        {isFormField && !label && <span className="mb-[2.12rem]"></span>}
        <div
          className={`relative w-full font-normal min-w-[230px] rounded bg-white py-[6.35px] px-4 outline-none ${borderOnFocusClass}  ${className} ${
            isInputFocused && "shadow-md"
          } ${props.isDisabled && "pointer-events-none"} ${
            !error && styles["input-wrapper"]
          } transition-shadow ${
            error ? "border-red-500 border" : `${borderClass}`
          } appearance-none`}
          onClick={handleParentDivClick}
        >
          {label && labelInField && (
            <label
              className={`absolute block font-normal bg-white px-2 left-4 -top-4 text-light-primary dark:text-dark-primary dark:bg-dark-background ${labelClass}`}
            >
              {label}
              {required && <span className="text-red-600 -mt-5 p-1">*</span>}
            </label>
          )}
          {isCreatable ? (
            <ReactSelectCreatable
              ref={selectRef}
              isMulti={isMulti ? true : undefined}
              defaultValue={defaultValue}
              name={name}
              id={name}
              value={selectedValue}
              options={options}
              styles={customStyles}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={placeholder}
              isClearable={clearable}
              isSearchable={searchable}
              onChange={formikHandleChange}
              {...props}
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
            />
          ) : (
            <ReactSelect
              ref={selectRef}
              isMulti={isMulti ? true : undefined}
              defaultValue={defaultValue}
              name={name}
              value={selectedValue}
              id={name}
              options={options}
              styles={customStyles}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={placeholder}
              isClearable={clearable}
              isSearchable={searchable}
              onChange={formikHandleChange}
              {...props}
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
              isDisabled={props.isDisabled}
            />
          )}
        </div>
        {typeof error === "string" && (
          <div className="text-red-500 text-sm font-normal my-1">{error}</div>
        )}
      </div>
    </>
  );
};

export const OtpInputMain = ({
  width,
  error,
  label,
  numInputs,
  labelClass,
  required,
  data,
  value,
  isFormField,
  formikHandleChange,
  placeholder,
  name,
  className,
  //@ts-ignore
  ...props
}: {
  width?: string;
  numInputs: number;
  error?: string | false | undefined;
  label?: string;
  labelClass?: string;
  formikHandleChange: (value: string | undefined) => void;
  required?: boolean;
  data?: any;
  value: any;
  isFormField?: boolean;
  placeholder?: string;
  name: string;
  className?: string;
  [key: string]: any;
}) => {
  const focusRef = useRef<HTMLInputElement>(null);

  const handleParentDivClick = () => {
    if (focusRef.current) {
      focusRef.current.focus();
    }
  };

  const [inputStyle, setInputStyle] = useState<CSSProperties>({
    width: "100px",
    height: "100px",
    margin: "0 4px",
    fontSize: "42px",
    borderRadius: "8px",
    border: "2px solid gray",
    textAlign: "center",
    color: "black",
    outline: "none",
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setInputStyle((prevStyle) => ({
          ...prevStyle,
          width: "80px",
          height: "80px",
        }));
      } else {
        setInputStyle((prevStyle) => ({
          ...prevStyle,
          width: "100px",
          height: "100px",
        }));
      }
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        className={`flex justify-center items-center `}
        onClick={handleParentDivClick}
      >
        {/* <div
          className={`relative bg-white flex justify-center items-center gap-1 dark:bg-dark-background dark:text-dark-primary ${
            isFormField && !label && "mt-[2.12rem]"
          } font-normal rounded bg-transparent py-[13.39px] px-6 text-black outline-none border-2 focus-within:border-light-secondary dark:focus-within:border-dark-foreground focus-within:shadow-lg transition-shadow ${
            error
              ? "border-red-500 focus-within:!border-red-500"
              : "border-stroke border-[#00336653] dark:border-dark-secondary"
          }`}
        > */}
        {/* {label && (
            <label
              className={`absolute block font-normal bg-white px-2 left-4 -top-4 text-light-primary dark:text-dark-primary dark:bg-dark-background ${labelClass}`}
            >
              {label}
              {required && <span className="text-red-600 -mt-5 p-1">*</span>}
            </label>
          )} */}
        <div className="space-y-2">
          <OTPInput
            value={value}
            onChange={formikHandleChange}
            numInputs={numInputs}
            // inputStyle={{
            //   maxWidth: "100px", // Makes input boxes bigger
            //   maxHeight: "100px", // Adjust height to match the width for a square appearance
            //   margin: "0 4px", // Adds some space between the input boxes
            //   fontSize: "42px", // Increases the font size for better readability
            //   borderRadius: "8px", // Adds rounded corners for a modern look
            //   border: "2px solid gray", // Default border color
            //   textAlign: "center", // Centers the text/input
            //   color: "black",
            //   outline: "none",
            // }}
            inputStyle={inputStyle}
            shouldAutoFocus
            renderInput={(props) => <input {...props} />}
          />
          {typeof error === "string" && (
            <div className="text-red-500 text-sm font-normal my-1">{error}</div>
          )}
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export const TextFieldAlt = ({
  autoFocus = false,
  error,
  inline,
  label,
  textarea,
  textFieldDivCss,
  rewrite,
  labelClass,
  addressField,
  onPlaceSelected,
  value,
  inputType,
  required,
  isFormField,
  className,
  name,
  borderOnFocusClass,
  borderClass,
  ...props
}: {
  [key: string]: any;
  value: string;
  name: string;
  autoFocus?: boolean;
  error?: any;
  inline?: string;
  label?: string;
  textarea?: string;
  textFieldDivCss?: string;
  rewrite?: string;
  labelClass?: string;
  addressField?: string;
  onPlaceSelected?: string;
  inputType?: string;
  isFormField?: boolean;
  required?: boolean;
  className?: string;
  borderOnFocusClass: string;
  borderClass?: string;
}) => {
  const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);

  const handleParentDivClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div
      className={`${styles.row} ${styles["input-container"]} w-full ${
        error ? styles.error : ""
      } ${textFieldDivCss} ${rewrite && ""}`}
    >
      {label && (
        <label
          className={`block mb-2 font-normal bg-transparent w-fit px-2 text-light-primary dark:text-dark-primary ${labelClass}`}
          htmlFor={name}
        >
          {label}
          {required && <span className="text-red-600 -mt-5 p-1">*</span>}
        </label>
      )}
      <div
        className={`relative bg-white dark:bg-dark-background flex justify-center items-center gap-1 dark:text-dark-primary ${
          isFormField && !label && "mt-[2.12rem]"
        } ${className} font-normal rounded-lg bg-transparent ${
          inputType === "email" ? "py-2" : "py-[13.39px]"
        }  px-6 text-black outline-none focus-within:shadow-md transition-shadow ${borderOnFocusClass} ${
          error
            ? "border-red-500 focus-within:border-red-500 border"
            : `border-stroke  ${borderClass}`
        }`}
        onClick={handleParentDivClick}
      >
        {inputType === "email" && (
          <img
            src={MessageIcon}
            className="w-8 mt-[2px] dark:brightness-200 dark:contrast-50"
          />
        )}
        {textarea ? (
          <textarea
            {...props}
            ref={inputRef}
            value={value}
            disabled={props.isDisabled}
            className="outline-none border-none w-full placeholder:text-light-secondary bg-transparent disabled:text-gray-400 dark:placeholder:text-dark-secondary"
          />
        ) : (
          <input
            {...props}
            ref={inputRef}
            type={inputType}
            value={value}
            id={name}
            autoFocus={autoFocus}
            disabled={props.isDisabled}
            className="outline-none border-none w-full placeholder:text-light-secondary disabled:text-gray-400 bg-transparent dark:placeholder:text-dark-secondary"
          />
        )}
      </div>

      {typeof error === "string" && (
        <div className="text-red-500 text-sm font-normal my-1">{error}</div>
      )}
    </div>
  );
};

export const PasswordFieldProtected = ({
  label,
  error,
  value = "",
  validator,
  inline,
  black,
  required,
  labelClass,
  isFormField,
  theme,
  borderClass,
  borderOnFocusClass,
  ...props
}: {
  label?: string;
  error?: string | false | undefined;
  value?: string;
  validator?: any;
  inline?: string;
  black?: string;
  required?: boolean;
  labelClass?: string;
  isFormField?: boolean;
  theme?: "light" | "dark";
  borderClass?: string;
  borderOnFocusClass: string;
  [key: string]: any;
}) => {
  const [toggle, setToggle] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleParentDivClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="w-full">
      <div className={`${styles.row} w-full ${error ? styles.error : ""}`}>
        {label && (
          <label
            className={`block mb-2 font-normal bg-transparent w-fit text-light-primary dark:text-dark-primary ${labelClass}`}
            htmlFor={props?.name}
          >
            {label}
            {required && <span className="text-red-600 -mt-5 p-1">*</span>}
          </label>
        )}
        <div
          className={`relative flex justify-center items-center gap-1 bg-white dark:bg-dark-background dark:text-dark-primary ${
            isFormField && !label && "mt-[2.12rem]"
          } font-normal rounded bg-transparent py-3 px-6 text-black outline-none ${borderOnFocusClass} transition-shadow ${
            error
              ? "border-red-500 focus-within:!border-red-500 border"
              : `border-stroke ${borderClass}`
          }`}
          onClick={handleParentDivClick}
        >
          {images.Lock2Icon(
            `w-5 h-5 mr-3 ${
              theme === "light"
                ? "stroke-black fill-transparent"
                : "stroke-dark-border fill-transparent"
            }`
          )}
          <input
            {...props}
            value={value}
            ref={inputRef}
            type={toggle ? "text" : "password"}
            className="bg-transparent outline-none border-none w-full placeholder:text-light-secondary dark:placeholder-dark-secondary"
          />
          <span
            className="absolute right-5 cursor-pointer"
            onClick={() => setToggle(!toggle)}
          >
            {toggle ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
          </span>
        </div>
        {validator !== true && typeof error === "string" && (
          <div className="text-red-500 text-sm font-normal my-1">{error}</div>
        )}
      </div>
      {validator && (
        <div
          className={`mt-2 flex items-center justify-start gap-2 lg:gap-4 text-light-foreground dark:text-dark-primary`}
        >
          <div className="flex items-center justify-center gap-2">
            {value?.length > 7 ? (
              <FaCircleCheck className="text-green-700" />
            ) : (
              <FaExclamationTriangle className="text-red-600" />
            )}
            <span className="text-xs">8-25 Char</span>
          </div>
          <div className="flex items-center justify-center gap-2">
            {checkUppercase(value) ? (
              <FaCircleCheck className="text-green-700" />
            ) : (
              <FaExclamationTriangle className="text-red-600" />
            )}
            <span className="text-xs">1 Upper Case</span>
          </div>
          <div className="flex items-center justify-center gap-2">
            {/\d/.test(value) ? (
              <FaCircleCheck className="text-green-700" />
            ) : (
              <FaExclamationTriangle className="text-red-600" />
            )}
            <span className="text-xs">1 Number</span>
          </div>
          <div className="flex items-center justify-center gap-2">
            {/[!@#$%^&*(),.?":{}|<>]/.test(value) ? (
              <FaCircleCheck className="text-green-700" />
            ) : (
              <FaExclamationTriangle className="text-red-600" />
            )}
            <span className="text-xs">1 Special Char</span>
          </div>
        </div>
      )}
    </div>
  );
};

export const DateTimePickerCustom = ({
  autoFocus = false,
  error,
  inline,
  label,
  rewrite,
  labelClass,
  onPlaceSelected,
  value,
  required,
  className,
  name,
  borderOnFocusClass,
  borderClass,
  onChange,
  ...props
}: {
  [key: string]: any;
  value: string;
  name: string;
  autoFocus?: boolean;
  error?: any;
  inline?: string;
  label?: string;
  rewrite?: string;
  labelClass?: string;
  onPlaceSelected?: string;
  required?: boolean;
  className?: string;
  borderOnFocusClass: string;
  borderClass?: string;
  onChange: (selected: any) => void;
}) => {
  const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);

  const handleParentDivClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div
      className={`${styles.row} ${styles["input-container"]} w-full ${
        error ? styles.error : ""
      } ${rewrite && ""}`}
    >
      {label && (
        <label
          className={`block mb-2 font-normal bg-transparent w-fit px-2 text-light-primary dark:text-dark-primary ${labelClass}`}
          htmlFor={name}
        >
          {label}
          {required && <span className="text-red-600 -mt-5 p-1">*</span>}
        </label>
      )}
      <div
        className={`relative bg-white dark:bg-dark-background flex justify-start items-center gap-1 py-[12px] dark:text-dark-primary  ${className} font-normal rounded-lg bg-transparent px-6 text-black outline-none focus-within:shadow-md transition-shadow ${borderOnFocusClass} ${
          error
            ? "border-red-500 focus-within:border-red-500 border"
            : `border-stroke  ${borderClass}`
        }`}
        onClick={handleParentDivClick}
      >
        <DateTimePicker onChange={onChange} value={value} />
      </div>

      {typeof error === "string" && (
        <div className="text-red-500 text-sm font-normal my-1">{error}</div>
      )}
    </div>
  );
};
export const DatePickerCustom = ({
  autoFocus = false,
  error,
  inline,
  label,
  rewrite,
  labelClass,
  onPlaceSelected,
  value,
  required,
  className,
  name,
  borderOnFocusClass,
  borderClass,
  onChange,
  ...props
}: {
  [key: string]: any;
  value: string;
  name: string;
  autoFocus?: boolean;
  error?: any;
  inline?: string;
  label?: string;
  rewrite?: string;
  labelClass?: string;
  onPlaceSelected?: string;
  required?: boolean;
  className?: string;
  borderOnFocusClass: string;
  borderClass?: string;
  onChange: (selected: any) => void;
}) => {
  const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);

  const handleParentDivClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  type ValuePiece = Date | null;

  type Value = ValuePiece | [ValuePiece, ValuePiece];

  return (
    <div
      className={`${styles.row} ${styles["input-container"]} w-full ${
        error ? styles.error : ""
      } ${rewrite && ""}`}
    >
      {label && (
        <label
          className={`block mb-2 font-normal bg-transparent w-fit px-2 text-light-primary dark:text-dark-primary ${labelClass}`}
          htmlFor={name}
        >
          {label}
          {required && <span className="text-red-600 -mt-5 p-1">*</span>}
        </label>
      )}
      <div
        className={`relative bg-white dark:bg-dark-background flex justify-start items-center gap-1 py-[12px] dark:text-dark-primary  ${className} font-normal rounded-lg bg-transparent px-6 text-black outline-none focus-within:shadow-md transition-shadow ${borderOnFocusClass} ${
          error
            ? "border-red-500 focus-within:border-red-500 border"
            : `border-stroke  ${borderClass}`
        }`}
        onClick={handleParentDivClick}
      >
        <DatePicker
          className={"w-full dark:text-dark-background  "}
          onChange={onChange}
          value={value}
        />
      </div>

      {typeof error === "string" && (
        <div className="text-red-500 text-sm font-normal my-1">{error}</div>
      )}
    </div>
  );
};

export const NumberInput = ({
  width,
  error,
  labelClass,
  label,
  required,
  name,
  inputValue,
  formikHandleChange,
  isFormField,
  placeholder,
  className,
  inputWidth,
  borderClass,
  borderOnFocusClass,
  max,
  ...props
}: {
  width?: string;
  inputWidth?: string;
  error?: string | false | undefined;
  placeholder: string;
  labelClass?: string;
  label?: string;
  required?: boolean;
  name: string;
  inputValue: number | null;
  formikHandleChange?: any;
  isFormField?: boolean;
  className?: string;
  borderClass?: string;
  borderOnFocusClass: string;
  max?: string;
  [key: string]: any;
}) => {
  // @ts-ignore
  const [isInputFocused, setIsInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleParentDivClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <>
      <div
        className={`${styles.row} ${width ? width : "w-full"} ${
          error ? styles.error : ""
        } `}
        onClick={handleParentDivClick}
      >
        {label && (
          <label
            className={`mb-2.5 block font-normal text-black dark:text-white ${labelClass}`}
          >
            {label}
            {required && <span className="text-red-600 -mt-5 p-1">*</span>}
          </label>
        )}
        {isFormField && !label && <span className="mb-[2.12rem]"></span>}
        <div
          className={`relative bg-white dark:bg-dark-background flex justify-start items-center gap-1 py-[12px] dark:text-dark-primary  ${className} font-normal rounded-lg bg-transparent px-6 text-black outline-none focus-within:shadow-md transition-shadow ${borderOnFocusClass} ${
            error
              ? "border-red-500 focus-within:border-red-500 border"
              : `border-stroke  ${borderClass}`
          }`}
        >
          <input
            ref={inputRef}
            className={`px-1 w-full bg-transparent placeholder:text-[#00336680] dark:placeholder:text-[#FFFFFF80]  ${
              inputWidth ? inputWidth : "md:max-w-[100px]"
            } outline-none bg-whiteSmoke`}
            type="number"
            min={0}
            max={max}
            name={name}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
            value={inputValue ? inputValue : ""}
            onChange={(event) => {
              if (event.target.value.length <= 5) {
                formikHandleChange(event);
              }
            }}
            placeholder={!inputValue ? placeholder : ""}
          />
        </div>
        {typeof error === "string" && (
          <div className="text-red-500 text-sm font-normal my-1">{error}</div>
        )}
      </div>
    </>
  );
};

export const TagsInputMain = ({
  autoFocus = false,
  error,
  inline,
  label,
  textarea,
  textFieldDivCss,
  rewrite,
  labelClass,
  addressField,
  onPlaceSelected,
  value,
  inputType,
  required,
  isFormField,
  className,
  name,
  borderOnFocusClass,
  borderClass,
  disabled,
  handleTagsChange,
  ...props
}: {
  [key: string]: any;
  value: string[];
  disabled?: boolean;
  name: string;
  autoFocus?: boolean;
  error?: any;
  inline?: string;
  label?: string;
  textarea?: string;
  textFieldDivCss?: string;
  rewrite?: string;
  labelClass?: string;
  addressField?: string;
  onPlaceSelected?: string;
  inputType?: string;
  isFormField?: boolean;
  required?: boolean;
  className?: string;
  borderOnFocusClass: string;
  borderClass?: string;
  handleTagsChange: (tags: string[]) => void;
}) => {
  const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);

  const handleParentDivClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div
      className={`${styles.row} ${styles["input-container"]} w-full ${
        error ? styles.error : ""
      } ${textFieldDivCss} ${rewrite && ""}`}
    >
      {label && (
        <label
          className={`block mb-2 font-normal bg-transparent w-fit px-2 ${labelClass}`}
          htmlFor={name}
        >
          {label}
          {required && <span className="text-red-600 -mt-5 p-1">*</span>}
        </label>
      )}
      <div
        className={`relative bg-white dark:bg-dark-background flex justify-center items-center gap-1 dark:text-dark-primary ${
          isFormField && !label && "mt-[2.12rem]"
        } ${className} font-normal rounded-lg bg-transparent pt-[10px] pb-[5px] px-6 text-black outline-none focus-within:shadow-md transition-shadow ${borderOnFocusClass} ${
          error
            ? "border-red-500 focus-within:border-red-500 border"
            : `border-stroke  ${borderClass}`
        }`}
        onClick={handleParentDivClick}
      >
        <TagsInput
          value={value}
          onChange={handleTagsChange}
          inputProps={{ placeholder: `Add a ${name}` }}
          className="w-full"
          disabled={disabled}
        />
      </div>
    </div>
  );
};
